import {useState} from "react";

export function useToken() {

    const getToken = () => {
        return JSON.parse(localStorage.getItem('ts.token'));
    };

    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
        localStorage.setItem('ts.token', JSON.stringify(userToken));
        setToken(userToken);
    };

    return {
        setToken: saveToken,
        token
    }
}

export function usePermitted() {
    const getPermitted = () => {
        return getToken() != null && getRoles() != null;
    };
    const [permitted, setPermitted] = useState(getPermitted());

    const savePermitted = token => {
        setPermitted(token != null && token.accessToken != null);
    }

    return {
        setPermitted: savePermitted,
        permitted
    }
}

export function useRoles() {

    const getRoles = () => {
        const token = getToken();
        if (token != null && token.secD != null) {
            return atob(token.secD);
        }
        return null; 
    }

    const [roles, setRoles] = useState(getRoles());

    const saveRoles = token => {
        if (token != null && token.secD != null) {
            setRoles(atob(token.secD));
        }
    }

    return {
        roles,
        setRoles: saveRoles
    }
}

export function useAccount() {

    const getAccount = () => {
        const token = getToken();
        if (token != null && token.perD != null) {
            return JSON.parse(atob(token.perD));
        }
        return null; 
    }

    const [account, setAccount] = useState(getAccount());

    const saveAccount = account => {
        setAccount(account);
    }

    return {
        account,
        setAccount: saveAccount
    }
}

export function getAccount() {
    const token = getToken();
    if (token != null && token.perD != null) {
        return JSON.parse(atob(token.perD));
    }
    return null; 
}

export function getToken() {
    return JSON.parse(localStorage.getItem('ts.token'));
}

export function getRoles() {
    let roles = null;
    const token = getToken();
    if (token != null && token.secD != null) {
        roles = atob(token.secD);
    }
    return roles;
}

export function isInRole(role) {
    const roles = getRoles();
    return roles != null && roles.indexOf(role) > -1;
}

export function isInAnyRole() {
    return getRoles() != null;
}