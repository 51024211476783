import ReactApexChart from "react-apexcharts";
import { FcBullish, FcBearish } from "react-icons/fc";
import { AiOutlinePoweroff } from "react-icons/ai";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { VscTarget } from "react-icons/vsc";

export default function PurchaseItem(props) {
    const purchaseStatus = props.stock.purchaseStatus;
    const recommendation = props.stock.recommendation;
    
    let chartSeries = [recommendation.buyCount, recommendation.holdCount, recommendation.sellCount];    
    const vorzeichen = purchaseStatus.priceChange > 0 ? "+" : "";
    const getBadge = () => {
        let text = null;
        let color = null;
        let textColor = "white";
        if (recommendation.signal === "STRONG_BUY") {
            color = "green";
            text = "Strong Buy"
        } else if (recommendation.signal === "BUY") {
            color = "lightgreen";
            text = "Buy";
            textColor = "black";
        } else if (recommendation.signal === "HOLD") {
            color = "yellow";
            text = "Hold";
        } else if (recommendation.signal === "SELL") {
            color = "orange";
            text = "Sell";
        } else if (recommendation.signal === "STRONG_SELL") {
            color = "red";
            text = "Strong Sell";
        } else {
            return null;
        }
        return <div style={{
            backgroundColor: color, 
            color: textColor, 
            textAlign: "center",
            width: "100px",
            borderRadius: "10px",
            fontSize: "12px"}}>{text}</div>
    }

    const trendIcon = props.type === "negative" ? <FcBearish size={20}/> : (props.type === "positive" ? <FcBullish size={20} /> : <AiOutlinePoweroff size={20} />);
    const trendColor = props.type === "negative" ? "red" : ("positive" ? "green" : "silver");
    const priceChangePrognose = props.stock.priceChangePrognose != null ? (props.stock.priceChangePrognose > 0 ? "+" + props.stock.priceChangePrognose.toFixed(2) + "$" : props.stock.priceChangePrognose.toFixed(2) + "$") : "-";
    const quoteChangePrognose = props.stock.quoteChangePrognose != null ? (props.stock.quoteChangePrognose > 0 ? "+" + props.stock.quoteChangePrognose.toFixed(2) : props.stock.priceChangePrognose.toFixed(2)) : "-"
    const quoteDiff = purchaseStatus.currentQuote - purchaseStatus.startQuote;
    const quoteChange = quoteDiff >= 0 ? "+" + quoteDiff.toFixed(2) : quoteDiff.toFixed(2);
    const quoteChangePerc =  purchaseStatus.startQuote > 0 ? (100*quoteChange/purchaseStatus.startQuote).toFixed(2) + "%" : "-"

    const getPrevSignal = () => {
        let color = null;
        if (recommendation.prevSignal === "STRONG_BUY") {
            color = "green";
        } else if (recommendation.prevSignal === "BUY") {
            color = "lightgreen";
        } else if (recommendation.prevSignal === "HOLD") {
            color = "yellow";
        } else if (recommendation.prevSignal === "SELL") {
            color = "orange";
        } else if (recommendation.prevSignal === "STRONG_SELL") {
            color = "red";
        } else {
            return null;
        }
        return <div style={{display: "flex"}}><div style={{
            backgroundColor: color,
            padding: "5px",
            width: "18px",
            height: "18px",
            borderRadius: "20px",
        }}/><MdKeyboardDoubleArrowRight size={20}/></div>
    }
    
    const chartOptions = {
        chart: {
            type: 'donut',
            width: '100px',
            height: '100px'
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            position: "right",
            offsetY: -20,
            offsetX: 10,
            labels: {
              colors: "#FFFFFF",
              useSeriesColors: false,
            },
            markers: {
                radius: 2,
                width: 5
            }
        },
        colors: ["#2bc336","#f4ee3a", "#f4423a"],
        labels: [
            "Buy: " + recommendation.buyCount, 
            "Hold: " + recommendation.holdCount, 
            "Sell: " + recommendation.sellCount, 
        ]
    }



    return (
        <div className="w3-quarter" style={{marginBottom: "5px"}}>
            <div className="w3-container w3-padding-16" style={{backgroundColor: "#1b1e22", borderRadius: "5px"}}>
                <div style={{display: "flex"}}>
                    <div style={{width: "40%"}}>
                        <h5>{trendIcon} {purchaseStatus.symbol}</h5>
                        <div style={{fontSize: "18px", padding: "0px 5px", textAlign: "center", backgroundColor: trendColor, borderRadius: "4px"}}>{vorzeichen}{purchaseStatus.priceChange.toFixed(2)}$</div><br />
                        <div>{quoteChange}/{quoteChangePerc}</div>
                    </div>
                    <div style={{width: "60%"}}><ReactApexChart width={200} options={chartOptions} series={chartSeries} type="donut" /></div>
                </div>
                <hr />
                <div style={{display: "flex"}}>
                    <div style={{width: "50%"}}>
                        <VscTarget /> {quoteChangePrognose}/{priceChangePrognose}
                    </div>
                    <div style={{width: "50%", display: "flex", textAlign: "end"}}>
                        <div style={{width: "40px"}}>{getPrevSignal()}</div> 
                        <div>{getBadge()}</div>
                    </div>
                </div>
            </div>
        </div>
    );


}