import {useHistory} from "react-router";

export default function AdminDashboard(props) {

    const history = useHistory();
    return (
        <div style={{padding: "20px"}}>
            <h3>Admin-Dashboard</h3>
            <ul>
                <li style={{cursor: "pointer"}} onClick={() => history.push("/admin/test-analysis")}>Test Analysis</li>
            </ul>
        </div>
    );
}
