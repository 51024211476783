export function generateCandleStickData(candles) {
    let data = [];

    let timestamps = candles.t;
    let closePrices = candles.c;
    let highPrices = candles.h;
    let lowPrices = candles.l;
    let openPrices = candles.o;

    for(let i = 0; i < timestamps.length; i++) {
        let point = [timestamps[i], openPrices[i], highPrices[i], lowPrices[i], closePrices[i]];
        data.push(point);
    }

    return data;
}