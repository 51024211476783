import PurchaseItem from "./TrendingStockItem";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useState } from "react";

export default function TrendingStocksList(props) {

    const [showModal, setShowModal] = useState(false);
    const [selectedStock, setSelectedStock] = useState(null);

    const positiveStocks = props.positiveStocks;
    const negativeStocks = props.negativeStocks;

    const positiveTitle = "Uptrend (" + positiveStocks.length + ")";
    const negativeTitle = "Downtrend (" + negativeStocks.length + ")";

    return (
        <>
            <Tabs
            defaultActiveKey="positive"
            id="stocks-tabs"
            className="mb-3"
            >
                <Tab eventKey="positive" title={positiveTitle} style={{color: "#fefefe"}}>
                    <div className="w3-row-padding w3-margin-bottom">
                        {positiveStocks.length > 0 ?
                            positiveStocks.map(s => (
                                <div onClick={() => {
                                    setShowModal(true);
                                    setSelectedStock(s);
                                }} style={{cursor: "pointer"}}><PurchaseItem stock={s} type="positive" /></div>
                            ))
                        :
                            <div style={{textAlign: "center", padding: "20px"}}>Keine Stocks mit Uptrend vorhanden</div>
                        }
                    </div>
                </Tab>
                <Tab eventKey="negative" title={negativeTitle} style={{color: "#fefefe"}}>
                    <div className="w3-row-padding w3-margin-bottom">
                        {negativeStocks.length > 0 ?
                            negativeStocks.map(s => (
                                <div onClick={() => {
                                    setShowModal(true);
                                    setSelectedStock(s);
                                }} style={{cursor: "pointer"}}><PurchaseItem stock={s} type="negative" /></div>
                            ))
                        :
                            <div style={{textAlign: "center", padding: "20px"}}>Keine Stocks mit Downtrend vorhanden</div>
                        }
                    </div>
                </Tab>
                
            </Tabs>  
        </>
    );
}