import {useState} from "react";

export default function WatchlistItem(props) {
    
    const [item] = useState(props.watchlistItem);
    const [periodResult] = useState(props.periodResult);

    //Paid
    if (periodResult != null) {
        return (
            <div style={{width: "100%", display: "flex"}}>
                <div style={{width: "10%"}}>{item.symbol}</div>
            </div>
        );
    }

    //Not Paid
    return (
        <>

        </>
    );
}
