import {Button, Col, Form, Row} from "react-bootstrap";
import React, {Component} from "react";
import {showError, showInfo, withToast} from "../util/ToastService";
import {GetCompanyProfileFromFinnhub, GetCompanyProfileFromTramis, SynchronizeCompanyProfile} from "./SymbolService";
import {FiRefreshCcw} from "react-icons/fi";
import {IoIosArrowBack} from "react-icons/io";
import {Link} from "react-router-dom";
import {GetStockCandleFromFinnhub} from "../stocks/StockService";
import Chart from "react-apexcharts";
import {generateCandleStickData} from "../stocks/StockHelper";

class SymbolDetails extends Component{

    constructor(props) {
        super(props);

        this.state = {
            symbolName: "",
            symbol: {},
            title: "Company: ",
            lastSync: "Nie",

            //Candle
            candleFilter: {
                symbol: "",
                resolution: "60",
                from: "",
                to: "",
            },
            candles: {t: [], o: [], h: [], l: [], c: [], v: []},
        }
    }

    async componentDidMount(){
        this.updateState('symbolName', this.parseCompany());

        //Load the symbol
        await this.loadSymbol();

        //Get the initial settings for the candle diagram
        let yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        let candleFilter = {symbol: this.state.symbol.symbol, resolution: this.state.candleFilter.resolution, from: yesterday.getTime(), to: Date.now()}
        this.updateState('candleFilter', candleFilter);

        //Load the candles
        this.loadCandles();
    }

    render() {

        const detailStyle = {
            borderLeftStyle: "solid",
            borderLeftWidth: "4px",
            borderLeftColor: '#1A4466'
        }

        let options = {
            chart: {
                id: "basic-bar"
            },
        };
        let series = [
            {
                name: "series-1",
                data: generateCandleStickData(this.state.candles)
            }
        ];

        return (
            <>
                <div className="board-title">{this.state.title}</div>
                <div className="board-content-box" style={{height: "1200px"}}>
                    <div>
                        <Link to={'/symbols'}><Button variant="secondary"><IoIosArrowBack /> Zurück</Button></Link>
                        <span style={{float: "right"}}>
                             <span style={{fontWeight: "bold", marginRight: "10px"}}>Zuletzt synchronisiert: {this.state.lastSync}</span> <Button variant="outline-secondary" onClick={() => this.syncSymbol()}><FiRefreshCcw /> Synchronisieren</Button>
                        </span>
                    </div>
                    <hr/>
                    <div style={{textAlign: "center"}}>
                        <img src={this.state.symbol.logo} alt={"Logo of company " + this.state.symbol.name} width={200} />
                    </div>
                    <div style={{textAlign: "center", marginTop: "20px"}}>
                        <a href={this.state.symbol.weburl} target={"_blank"}  rel={"noreferrer"} style={{textDecoration: "none", color: "#4e8abb"}}><h2>{this.state.symbol.name}</h2></a>
                        <span style={{fontStyle: "italic"}}>{this.state.symbol.country} - {this.state.symbol.currency}</span>
                    </div>
                    <hr />
                    <Form style={{fontSize: "24px"}}>
                        <Row>
                            <Col style={detailStyle}>
                                <span style={{fontWeight: "bold", textDecoration: "underline"}}>Exchange:</span>
                                <br />
                                <span>{this.state.symbol.exchange}</span>
                            </Col>
                            <Col style={detailStyle}>
                                <span style={{fontWeight: "bold", textDecoration: "underline"}}>Finnhub Industry:</span>
                                <br />
                                <span>{this.state.symbol.finnhubIndustry}</span>
                            </Col>
                        </Row>
                        <Row style={{marginTop: "50px"}}>
                            <Col style={detailStyle}>
                                <span style={{fontWeight: "bold", textDecoration: "underline"}}>Market Capitalization:</span>
                                <br />
                                <span>{this.state.symbol.marketCapitalization}</span>
                            </Col>
                            <Col style={detailStyle}>
                                <span style={{fontWeight: "bold", textDecoration: "underline"}}>Share Outstanding</span>
                                <br />
                                <span>{this.state.symbol.shareOutstanding}</span>
                            </Col>
                        </Row>
                    </Form>

                    <div style={{marginTop: "100px"}}>
                        <Chart
                            options={options}
                            series={series}
                            type="candlestick"
                            width="1500"
                            height="400"
                        />
                    </div>

                </div>
            </>
        );
    }

    //-----------
    //API Methods
    //-----------

    async loadSymbol(){
        let response = await GetCompanyProfileFromTramis(this.state.symbolName);
        if(!response.error) {
            this.updateState('symbol', response.result);
            this.updateState('title', "Company: " + response.result.name)
            this.updateState('lastSync', response.result.lastSync.toString().replace("T", " um "));
        } else {
            showInfo(this.props, "Company " + this.state.symbolName + " ist noch nicht in der Datenbank.");
        }
    }

    async syncSymbol() {
        let profileFromFinnhub = await GetCompanyProfileFromFinnhub(this.state.symbolName);
        if(!profileFromFinnhub.error) {
            let response = await SynchronizeCompanyProfile(profileFromFinnhub.result, this.state.symbolName);
            if(!response.error) {
                await this.loadSymbol();
            } else {
                showError(this.props, "Beim Erstellen des Profils ist ein Fehler aufgetreten.");
            }
        } else {
            showError(this.props, "Beim Laden des Profils ist ein Fehler aufgetreten.");
        }
    }

    async loadCandles() {
        let response = await GetStockCandleFromFinnhub(this.state.candleFilter);
        if(!response.error) {
            this.updateState('candles', response.result);
        } else {
            showError(this.props, "Beim Laden der Candle-Daten ist ein Fehler aufgetreten.");
        }
    }


    //-------
    //HELPER
    //-------

    parseCompany() {
        let path = window.location.pathname;
        let company = "";
        let companyIndex = path.indexOf("/", 1);
        if(companyIndex > -1) {
            company = path.substring(companyIndex + 1, path.length);
        }

        //Remove tailing stuff
        companyIndex = company.indexOf("/");
        if(companyIndex > -1) {
            company = company.substring(0, companyIndex);
        }

        return company;
    }

    resetState() {
        this.updateState('title', 'Symbols');
    }

    updateState(param, value) {
        let state = this.state;
        state[param] = value;
        this.setState(state);
    }
}

export default withToast(SymbolDetails);