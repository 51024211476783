import {
    GetTramisResource,
    CreateTramisResource,
    DeleteTramisResource,
    GetFilteredTramisResources,
    UpdateTramisResource
} from "../clients/TramisClient";

export async function GetFilteredWatchlists(accountId, filter) {
    return await GetFilteredTramisResources('api/accounts/' + accountId + '/watchlists', filter);
}

export async function CreateWatchlist(watchlist) {
    return await CreateTramisResource('api/accounts/' + watchlist.accountId + '/watchlists', watchlist);
}

export async function UpdateWatchlist(watchlist) {
    return await UpdateTramisResource('api/accounts/' + watchlist.accountId + '/watchlists/' + watchlist.id, watchlist);
}

export async function AddItemToWatchlist(accountId, watchlistItem) {
    return await CreateTramisResource('api/accounts/' + accountId + '/watchlists/' + watchlistItem.watchlistId + '/items?symbol=' + watchlistItem.symbol, watchlistItem);
}

export async function RemoveItemFromWatchlist(accountId, watchlistItem) {
    return await DeleteTramisResource('api/accounts/' + accountId + '/watchlists/' + watchlistItem.watchlistId + '/items/' + watchlistItem.id);
}

export async function GetWatchlistPurchaseDetails(accountId, watchlistId) {
    return await GetTramisResource('api/accounts/' + accountId + '/watchlists/' + watchlistId + '/purchses');
}

