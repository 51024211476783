const Strings = {
    //Global
    YES_BUTTON: "Ja",
    NO_BUTTON: "Nein",
    CANCEL_BUTTON: "Abbrechen",
    START_BUTTON: "Starten",
    LATER_BUTTON: "Später",
    DELETE_BUTTON: "Löschen",
    SAVE_BUTTON: "Speichern",
    OK_BUTTON: "Ok",

    //Navbar
    NAVBAR_SIGN_IN: "Anmelden",
    NAVBAR_SIGN_UP: "Registrieren",

    //Authentication
    AUTHENTICATION_TITLE: "Bei Tramis anmelden",
    AUTHENTICATION_EMAIL: "E-Mail",
    AUTHENTICATION_PASSWORD: "Passwort",

    //Dashboard
    DASHBOARD_PORTFOLIO_TITLE: "Willkommen zurück, ",
    DASHBOARD_WATCHLIST_TITLE: "Prognosen",
    DASHBOARD_EMPTY_WATCHLIST: "Deine Watchlist ist leer.<br/>Klicke hier, um Stocks jetzt hinzuzufügen",
    DASHBOARD_NO_WATCHLIST: "Du hast noch keine Watchlist erstellt. Klicke hier, um jetzt eine Watchlist zu erstellen",
    DASHBOARD_NO_PURCHASE_DETAILS: "In dieser Watchlist wurden noch keine Käufe getätigt. Klicke auf das Kauf-Icon des entsprechenden Symbols, um es der Statistik hinzuzufügen.",


    //Workout Board
    WORKOUTS_USER_TITLE: "Start your day right!",
    WORKOUTS_USER_HEADER: "Deine Workouts",
    WORKOUTS_NEW_BUTTON: "Neues Workout erstellen",
    WORKOUTS_COMMUNITY_TITLE: "Neu aus der Community",
    WORKOUTS_COMMUNITY_HEADER: "Community Workouts",
    WORKOUTS_COMMUNITY_ALL_BUTTON: "Alle Workouts",

    //Workout Board
    EXERCISES_USER_TITLE: "Von dir gemacht",
    EXERCISES_USER_HEADER: "Deine Übungen",
    EXERCISES_NEW_BUTTON: "Neue Übung erstellen",
    EXERCISES_COMMUNITY_TITLE: "Neu aus der Community",
    EXERCISES_COMMUNITY_HEADER: "Community Übungen",
    EXERCISES_COMMUNITY_ALL_BUTTON: "Alle Übungen",

    //Workout Editor
    WORKOUT_EDITOR_TITLE_A: "Workout ",
    WORKOUT_EDITOR_TITLE_B: " bearbeiten",
    WORKOUT_EDITOR_SAVE_BUTTON: "Änderungen speichern",

    WORKOUT_EDITOR_FINISHED_TITLE: "Geschafft!",
    WORKOUT_EDITOR_FINISHED_TEXT: "Die Änderungen wurden übernommen. Möchtest du das Workout jetzt starten?",

    WORKOUT_EDITOR_DELETE_TITLE: "Workout löschen",
    WORKOUT_EDITOR_DELETE_TEXT: "Möchtest du dieses Workout wirklich löschen? Dies kann nicht rückgängig gemacht werden!",

    WORKOUT_EDITOR_DELETION_SUCCESSFUL_TEXT: "Das Workout wurde erfolgreich gelöscht!",

};

export default Strings;