import {useState} from "react";
import {Button, Col, Form} from "react-bootstrap";
import {GetTramisResource} from "../clients/TramisClient";
import {convertCandleDataIntoApexForm} from "../util/ConverterUtil";
import WatchlistItem from "../watchlists/watchlistItems/WatchlistItem";
import {Label} from "reactstrap";

export default function AdminAnalysisTest(props) {

    const [analyzed, setAnalyzed] = useState(false);
    const [result, setResult] = useState(null);
    const [form, setForm] = useState({});

    const handleSubmit = async() => {
        let result = await GetTramisResource('api/analysis/perform-by-symbol?symbol=' + form.symbol + '&from=' + form.from + '&to=' + form.to);
        if (!result.error) {
            setResult(result.result);
            setAnalyzed(true);
        }
    }

    const updateForm = (field, value) => {
        setForm({
            ...form,
            [field]: value
        });
    }

    return (
        <div style={{padding: "20px"}}>
            <h1>Analysis Test</h1>
            <hr />
            <br />
            <p>
                <h3>1. Set data</h3>
                <Form>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Label>Symbol</Label>
                            <Form.Control value={form.symbol} onChange={(e) => updateForm('symbol', e.target.value)} />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Label>From</Label>
                            <Form.Control value={form.from} onChange={(e) => updateForm('from', e.target.value)} />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Label>To</Label>
                            <Form.Control value={form.to} onChange={(e) => updateForm('to', e.target.value)} />
                        </Form.Group>
                    </Form.Row>
                </Form>
                <Button variant="secondary" onClick={() => handleSubmit()}>Analyze</Button>
            </p>
            <br />
            <p>
                <h3>2. Results</h3>
                {analyzed && result != null &&
                    <>
                        <WatchlistItem chartId={"analysis-test"}
                                       candles={convertCandleDataIntoApexForm(result.candles)} item={result}
                                       show={true}
                                       highPass={null}/>
                    </>
                }
            </p>
        </div>
    );
}
