import {AiOutlineSync} from "react-icons/ai";
import {BiSearchAlt} from "react-icons/bi";
import {MdClear} from "react-icons/md";
import {ButtonGroup, Col, Dropdown, DropdownButton, Form, InputGroup, Row} from "react-bootstrap";
import {Label} from "reactstrap";
import {Button} from "react-bootstrap";
import {Component} from "react";
import {GetAllExchanges} from "../exchanges/ExchangeService";
import {
    GetFilteredStockSymbolsFromFinnhub,
    GetFilteredStockSymbolsFromTramis, GetLastSyncTimestamp, SearchForSymbolsOnFinnhub,
    SynchronizeSymbols
} from "./SymbolService";
import SymbolCard from "./SymbolCard";
import SymbolMetaData from "./SymbolMetaData";
import {showError, showInfo, withToast} from "../util/ToastService";
import {PaginationUtil} from "../util/PaginationUtil";

class Symbols extends Component{

    constructor(props) {
        super(props);

        this.state = {
            title: "Symbols",
            exchanges: [],
            filteredExchanges: [],
            filter: {
                exchange: "",
                description: "",
                symbol: "",
                page: 1,
                perPage: 20,
            },
            source: SymbolMetaData.SYMBOL_SOURCES[0],
            timezones: ['Europe', 'Australia', 'America', 'Asia', 'Atlantic', 'Africa', 'Pacific'],

            symbols: [],
            lastSync: "",
        }
    }

    async componentDidMount(){
        await this.loadExchanges();
    }

    render() {
        return (
            <>
                <div className="board-title">{this.state.title}</div>
                <div className="board-content-box" style={{height: "300px"}}>
                    <h4>
                        Filter
                    </h4>
                    <hr/>
                    <Form>
                        <Row>
                            <Col>
                                <Label style={{fontWeight: "bold"}}>Query</Label>
                                <Form.Control type="text" value={this.state.filter.description}
                                              onChange={(e) => this.updateFilter('description', e.target.value)}/>
                            </Col>
                            <Col>
                                <Label style={{fontWeight: "bold"}}>Exchange</Label>
                                <InputGroup>
                                    <Form.Control as="select" placeholder="Exchange"
                                                  onChange={(e) => this.updateExchange(e.target.value)}>
                                        <option></option>
                                        {this.state.filteredExchanges.map(ex => (
                                            <option value={ex.code}>{ex.code} - {ex.name}</option>
                                        ))}
                                    </Form.Control>
                                    <InputGroup.Append>
                                        <span>
                                            <DropdownButton style={{float: "left", marginRight: "5px"}} variant="secondary"
                                                            title={this.state.source}>
                                                {SymbolMetaData.SYMBOL_SOURCES.map(s => (
                                                    <Dropdown.Item onClick={() => this.updateState('source', s)}>{s}</Dropdown.Item>
                                                ))}
                                            </DropdownButton>
                                            <Button variant="secondary" disabled={this.state.filter.exchange === ""}
                                                    onClick={() => this.synchronizeSymbols()}><AiOutlineSync/></Button>
                                            <span style={{marginLeft: "10px", fontWeight: "bold"}}>{this.state.lastSync}</span>
                                        </span>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col>
                                <Label style={{fontWeight: "bold"}}>Zeitzonen</Label><br/>
                                <ButtonGroup>
                                    <Button
                                        variant={this.state.timezones.includes('Europe') ? "secondary" : "outline-secondary"}
                                        onClick={() => this.toggleTimezone('Europe')}>Europe</Button>
                                    <Button
                                        variant={this.state.timezones.includes('Australia') ? "secondary" : "outline-secondary"}
                                        onClick={() => this.toggleTimezone('Australia')}>Australia</Button>
                                    <Button
                                        variant={this.state.timezones.includes('America') ? "secondary" : "outline-secondary"}
                                        onClick={() => this.toggleTimezone('America')}>America</Button>
                                    <Button
                                        variant={this.state.timezones.includes('Asia') ? "secondary" : "outline-secondary"}
                                        onClick={() => this.toggleTimezone('Asia')}>Asia</Button>
                                    <Button
                                        variant={this.state.timezones.includes('Atlantic') ? "secondary" : "outline-secondary"}
                                        onClick={() => this.toggleTimezone('Atlantic')}>Atlantic</Button>
                                    <Button
                                        variant={this.state.timezones.includes('Africa') ? "secondary" : "outline-secondary"}
                                        onClick={() => this.toggleTimezone('Africa')}>Africa</Button>
                                    <Button
                                        variant={this.state.timezones.includes('Pacific') ? "secondary" : "outline-secondary"}
                                        onClick={() => this.toggleTimezone('Pacific')}>Pacific</Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </Form>
                    <hr/>
                    <div style={{marginTop: "5px"}}>

                        <span style={{float: "right"}}>
                            <Button variant="outline-secondary"
                                    type="button"
                                    style={{marginRight: "5px", marginTop: "2px"}}
                                    onClick={() => this.resetState()}><MdClear /> Reset</Button>
                            <Button variant="secondary"
                                    type="button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.updateState('title', 'Symbols');
                                        this.loadSymbols(1).then(r => r);
                                    }}><BiSearchAlt/> Suche</Button>
                        </span>
                    </div>
                </div>

                {this.state.symbols.length > 0 &&
                <>
                    <div className="board-content-box"
                         style={{marginTop: "50px", height: (250 + Math.ceil(this.state.symbols.length / 4)*270) + "px"}}>
                        {this.state.symbols.map(sym => (
                            <SymbolCard symbol={sym} loadSymbol={(symbol) => this.loadSymbolsByName(symbol)}/>
                        ))}

                        {this.state.filter.description === "" &&
                        <>
                            <hr/>
                            <PaginationUtil page={this.state.filter.page} loadPage={(page) => this.loadSymbols(page)}/>
                        </>
                        }

                    </div>
                </>
                }
            </>
        );
    }

    //API Methods
    async loadExchanges() {
        let response = await GetAllExchanges();
        if(!response.error) {
            this.setState(prevState => ({...prevState, exchanges: response.result, filteredExchanges: response.result}));
        }
    }

    async loadSymbols(page) {
        this.updateFilter('page', page);
        this.updateState('symbols', []);
        let response;
        if(this.state.filter.description !== "") {
            //Load by symbol lookup on finnhub
            response = await SearchForSymbolsOnFinnhub(this.state.filter.description);
            if(!response.error) {
                this.setState(prevState => ({...prevState, symbols: response.result.result}));
                if(response.result.count === 0) {
                    showInfo(this.props, "Es wurden keine Symbols für diese Filtereinstellungen gefunden.");
                }
            } else {
                showError(this.props, "Fehler beim Laden der Symbols: " + response.message);
            }
        } else if(this.state.filter.exchange !== ""){
            //Get by exchange
            if(this.state.source === SymbolMetaData.SYMBOL_SOURCES[0]) {
                response = await GetFilteredStockSymbolsFromTramis(this.state.filter);
            } else if(this.state.source === SymbolMetaData.SYMBOL_SOURCES[1]) {
                response = await GetFilteredStockSymbolsFromFinnhub(this.state.filter);
            } else {
                response = {error: true, result: [], message: "Unbekannte Quelle ausgewählt: " + this.state.source}
            }
            if(!response.error) {
                this.setState(prevState => ({...prevState, symbols: response.result}));
                if(response.result.length === 0) {
                    showInfo(this.props, "Es wurden keine Symbols für diese Filtereinstellungen gefunden.");
                }
            } else {
                showError(this.props, "Fehler beim Laden der Symbols: " + response.message);
            }
        } else {
            showInfo(this.props, "Bitte gib eine Query oder einen Exchange an.");
        }
    }

    async updateExchange(exchange) {
        this.updateState('symbols', []);
        this.updateFilter('exchange', exchange);
        this.updateFilter('description', "");
        this.updateFilter('symbol', "");
        this.updateState('source', SymbolMetaData.SYMBOL_SOURCES[0]);

        //Update the last sync
        await this.getLastSyncTimestamp(exchange);

        //Get the symbols
        let response = await GetFilteredStockSymbolsFromTramis({exchange: exchange});
        if(!response.error) {
            this.updateState('symbols', response.result);
        }
    }

    async loadSymbolsByName(symbol) {
        this.updateState('title', "Symbols: " + symbol.symbol);
        this.updateFilter('symbol', symbol.symbol);
        await this.loadSymbols(1);
    }

    async synchronizeSymbols() {
        if(this.state.filter.exchange !== "") {
            let symbols = await GetFilteredStockSymbolsFromFinnhub({exchange: this.state.filter.exchange});
            if(!symbols.error) {
                let response = await SynchronizeSymbols(this.state.filter.exchange, symbols.result);
                if(!response.error) {
                    this.updateState('source', SymbolMetaData.SYMBOL_SOURCES[0]);
                    let syncedSymbols = await GetFilteredStockSymbolsFromTramis({exchange: this.state.filter.exchange});
                    if(!syncedSymbols.error) {
                        this.updateState('symbols', syncedSymbols.result);
                        if(syncedSymbols.result.length > 0) {
                            this.updateState('lastSync', syncedSymbols.result[0].createdAt.toString().replace("T", " um "));
                        }
                    }
                }
            }
        }
    }

    async getLastSyncTimestamp(exchange) {
        if(exchange !== "") {
            let response = await GetLastSyncTimestamp(exchange);
            let lastSync;
            //TODO Find a better solution
            if(response.error) {
                lastSync = "Zuletzt synchronisiert: Nie";
            } else {
                lastSync = "Zuletzt synchronisiert: " + response.result.toString().replace("T", " um ");
            }
            this.updateState('lastSync', lastSync);
        } else {
            this.updateState('lastSync', "");
        }
    }


    //-------
    //HELPER
    //-------

    resetState() {
        this.updateState('title', 'Symbols');
        this.updateState('symbols', []);
        this.updateState('filter',{exchange: "", description: "", symbol: "", page: 1, perPage: 20});
        this.updateState('lastSync', '');
    }

    updateState(param, value) {
        let state = this.state;
        state[param] = value;
        this.setState(state);
    }

    toggleTimezone(zone) {
        //Toggle the zone
        let zones = this.state.timezones;
        let index = zones.indexOf(zone);

        if(index > -1) {
            zones.splice(index, 1);
        } else {
            zones = zones.concat(zone);
        }

        //Filter the exchanges
        let filteredExchanges = this.state.exchanges.filter(ex => zones.includes(ex.timezone.substring(0, ex.timezone.indexOf("/"))));

        this.setState(prevState => ({...prevState, timezones: zones, filteredExchanges: filteredExchanges}));
    }

    updateFilter(param, value) {
        let filter = this.state.filter;
        filter[param] = value;
        this.setState(prevState => ({...prevState, filter: filter}));
    }
}

export default withToast(Symbols);