export function PaginationUtil(props) {
    const paginationStyle = {
        width: "50px",
        height: "40px",
        float: "left",
        paddingTop: "4px",
        fontWeight: "bold",
        fontSize: "20px",
        textAlign: "center",
        borderStyle: "solid",
        borderWidth: "1px",
        cursor: "pointer"
    }
    return(
        <div style={{margin: "auto", width: "100%", display: "inline-block"}}>
            <hr />
            {props.page > 1 &&
                <>
                    <p style={paginationStyle} onClick={() => props.loadPage(1)}>&#171;</p>
                    <p style={paginationStyle} onClick={() => props.loadPage(props.page - 1)}>&#60;</p>
                </>
            }

            {props.page > 2 &&
                <p style={paginationStyle} onClick={() => props.loadPage(props.page - 2)}>{props.page - 2}</p>
            }

            {props.page > 1 &&
                <p style={paginationStyle} onClick={() => props.loadPage(props.page - 1)}>{props.page - 1}</p>
            }

            <p style={{...paginationStyle, color: "white", backgroundColor: "#4e8abb"}}>{props.page}</p>

            <p style={paginationStyle} onClick={() => props.loadPage(props.page + 1)}>{props.page + 1}</p>
            <p style={paginationStyle} onClick={() => props.loadPage(props.page + 2)}>{props.page + 2}</p>
            <p style={paginationStyle} onClick={() => props.loadPage(props.page + 1)}>&#62;</p>
        </div>
    );
}