import PurchaseItem from "./PurchaseItem";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import PurchaseItemSellModal from "./PurchaseItemSellModal";
import { useState } from "react";
import { getAccount } from "../auth/TokenStorageService";

export default function PurchasesList(props) {

    const [showModal, setShowModal] = useState(false);
    const [selectedStock, setSelectedStock] = useState(null);

    const account = getAccount();

    const positiveStocks = props.positiveStocks;
    const negativeStocks = props.negativeStocks;
    const inactiveStocks = props.inactiveStocks;

    const positiveTitle = "Uptrend (" + positiveStocks.length + ")";
    const negativeTitle = "Downtrend (" + negativeStocks.length + ")";
    const inactiveTitle = "Inaktiv (" + inactiveStocks.length + ")";

    return (
        <>
            <Tabs
            defaultActiveKey="positive"
            id="stocks-tabs"
            className="mb-3"
            >
                <Tab eventKey="positive" title={positiveTitle} style={{color: "#fefefe"}}>
                    <div className="w3-row-padding w3-margin-bottom">
                        {positiveStocks.length > 0 ?
                            positiveStocks.map(s => (
                                <div onClick={() => {
                                    setShowModal(true);
                                    setSelectedStock(s);
                                }} style={{cursor: "pointer"}}><PurchaseItem stock={s} type="positive" /></div>
                            ))
                        :
                            <div style={{textAlign: "center", padding: "20px"}}>Keine Stocks mit Uptrend vorhanden</div>
                        }
                    </div>
                </Tab>
                <Tab eventKey="negative" title={negativeTitle} style={{color: "#fefefe"}}>
                    <div className="w3-row-padding w3-margin-bottom">
                        {negativeStocks.length > 0 ?
                            negativeStocks.map(s => (
                                <div onClick={() => {
                                    setShowModal(true);
                                    setSelectedStock(s);
                                }} style={{cursor: "pointer"}}><PurchaseItem stock={s} type="negative" /></div>
                            ))
                        :
                            <div style={{textAlign: "center", padding: "20px"}}>Keine Stocks mit Downtrend vorhanden</div>
                        }
                    </div>
                </Tab>
                <Tab eventKey="inactive" title={inactiveTitle} style={{color: "#fefefe"}}>
                    <div className="w3-row-padding w3-margin-bottom">
                        {inactiveStocks.length > 0 ?
                            inactiveStocks.map(s => (
                                <div onClick={() => {
                                    setShowModal(true);
                                    setSelectedStock(s);
                                }} style={{cursor: "pointer"}}><PurchaseItem stock={s} type="inactive" /></div>
                            ))
                        :
                            <div style={{textAlign: "center", padding: "20px"}}>Keine inaktiven Stocks vorhanden</div>
                        }

                    </div>
                </Tab>
            </Tabs>  

            <PurchaseItemSellModal show={showModal} account={account} stock={selectedStock} onHide={() => {
                setShowModal(false);
                setSelectedStock(null);}} />
        </>
    );
}