import {useToasts} from "react-toast-notifications";

export function withToast(Component) {
    return function WrappedComponent(props) {
        const toastFuncs = useToasts();
        return <Component {...props} {...toastFuncs} />;
    }
}

export function showError(props, message) {
    props.addToast(message, {
        appearance: "error",
        autoDismiss: true
    });
}

export function showSuccess(props, message) {
    props.addToast(message, {
        appearance: "success",
        autoDismiss: true
    });
}
export function showInfo(props, message) {
    props.addToast(message, {
        appearance: "info",
        autoDismiss: true
    });
}