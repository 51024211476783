import {useState} from "react";
import {registerAccount} from "./PrincipalService";
import {Button, Form} from "react-bootstrap";
import {AiOutlineLogin} from "react-icons/ai";
import {Alert} from "react-bootstrap";
import {validateFirstChar, validateUserName} from "../util/StringUtil";
import {Link} from "react-router-dom";
import './AuthStyle.css';

export default function Registration(){
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [userName, setUserName] = useState("");
    const [email, setEmail] = useState("");
    const [confirmEmail, setConfirmEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [unknownError, setUnknownError] = useState(false);
    const [userNameError, setUserNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [userNameErrorText, setUserNameErrorText] = useState("");
    const [emailErrorText, setEmailErrorText] = useState("");
    const [passwordErrorText, setPasswordErrorText] = useState("");
    const [finished, setFinished] = useState(false);
    //const history = useHistory();

    const handleSubmit = async e => {

        e.preventDefault();

        //Reset error messages
        setUserNameError(false);
        setEmailError(false);
        setPasswordError(false);
        setEmailErrorText("");
        setPasswordErrorText("");

        //Validation
        if(userName === "") {
            setUserNameError(true);
            setUserNameErrorText("Bitte gibt einen Benutzername an!");
        } else if(!validateFirstChar(userName)) {
            setUserNameError(true);
            setUserNameErrorText("Der Benutzername muss mit einem Buchstaben anfangen (a-z,A-Z)")
        } else if(!validateUserName(userName)) {
            setUserNameError(true);
            setUserNameErrorText("Nur 'a-z,A-Z,0-9,_,-,+' sind erlaubt!")
        } else if(userName.length < 4 || userName.length > 20) {
            setUserNameError(true);
            setUserNameErrorText("Der Benutzername muss zwischen 4 - 20 Zeichen lang sein!");
        }

        if(email === "" || confirmEmail === "") {
            setEmailError(true);
            setEmailErrorText("Bitte gib deine E-Mail-Adresse an!");
        } else if(!email.includes("@")){
            setEmailError(true);
            setEmailErrorText("Bitte gib eine gültige E-Mail-Adresse an!");
        } else if(email !== confirmEmail) {
            setEmailError(true);
            setEmailErrorText("Die angegebenen E-Mail-Adressen stimmen nicht überein!");
        } else if(email.length > 50) {
            setEmailError(true);
            setEmailErrorText("Die E-Mail-Adresse darf nicht länger als 50 Zeichen lang sein!")
        }

        if(password === "" || confirmPassword === "") {
            setPasswordError(true);
            setPasswordErrorText("Bitte gib ein Passwort an!");
        } else if(password.length < 6 || password.length > 20) {
            setPasswordError(true);
            setPasswordErrorText("Das Passwort muss zwischen 6 - 20 Zeichen lang sein!");
        } else if(password !== confirmPassword) {
            setPasswordError(true);
            setPasswordErrorText("Die angegebenen Passwörter stimmen nicht überein!");
        }

        if(userNameError || emailError || passwordError) {
            return;
        }

        //Fire the request
        let response = await registerAccount({
            firstName: firstName,
            lastName: lastName,
            userName: userName,
            email: email,
            password: password
        });

        if(response.error) {
            //Check if the error is caused by existing email or username
            if(response.message.includes("E-Mail")) {
                setEmailError(true);
                setEmailErrorText("Es existiert bereits ein Account mit dieser E-Mail-Adresse!");
            } else if(response.message.includes("Username")) {
                setUserNameError(true);
                setUserNameErrorText("Dieser Benutzername wird bereits verwendet!");
            } else {
                setUnknownError(true);
            }
        } else {
            //Redirect to login page
            //history.push('/login');
            setFinished(true);
        }
    }

    return(
        <>
            {finished ?
                <>
                    <div className="w3-content w3-padding" style={{maxWidth: "360px", marginTop: "20px"}}>
                        <h2>Geschafft!</h2>
                        <hr />
                        <h5>Willkommen auf Tramis, {userName}!</h5>
                        <p>
                            Wir sind froh, dich als Teil der Community <span style={{fontWeight: "bold"}}>Tramis</span> begrüßen zu können. Klicke auf den folgenden Button, um dich erstmalig einzuloggen und direkt zu starten!
                        </p>

                        <Link to={"/login"}>
                            <Button variant="secondary">
                                <AiOutlineLogin />&#xA0;Einloggen
                            </Button>
                        </Link>

                    </div>
                </>
            :
                <div className="w3-main" style={{marginLeft: "300px", marginTop:"43px"}}>

                    {/* Header */}
                    <header className="w3-container" style={{paddingTop:"50px"}} />

                    <div className="w3-container authentication-form">
                        <Alert variant="danger" show={unknownError} onClick={() => setUnknownError(false)}>
                            <Alert.Heading>Ooops..</Alert.Heading>
                            <p>
                                Ein unbekannter Fehler ist während der Registrierung aufgetreten. Bitte versuche es später erneut.
                            </p>
                        </Alert>
                        <h3>Auf Tramis registrieren</h3>
                        <hr />
                        <Form onSubmit={handleSubmit} style={{marginTop: "25px"}}>

                            {/* OPTIONAL STUFF */}
                            <Form.Group className="mb-3" controlId="formBasicFirstName">
                                <Form.Control className='form-input'type="text" placeholder="Vorname" onChange={e => setFirstName(e.target.value)}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicLastName">
                                <Form.Control className='form-input' type="text" placeholder="Nachname" onChange={e => setLastName(e.target.value)}/>
                            </Form.Group>
                            <hr />

                            {/* USERNAME */}
                            <Alert variant="danger" show={userNameError}>{userNameErrorText}</Alert>
                            <Form.Group className="mb-3" controlId="formBasicUserName">
                                <Form.Control className='form-input' type="text" placeholder="Benutzername*" onChange={e => {
                                    if(userNameError && userName.length >= 4) {
                                        setUserNameError(false);
                                    }
                                    setUserName(e.target.value)
                                }}/>
                            </Form.Group>
                            <hr />

                            {/* E-MAIL */}
                            <Alert variant="danger" show={emailError}>{emailErrorText}</Alert>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Control className='form-input' type="email" placeholder="E-Mail*" onChange={e => {
                                    if(emailError) {
                                        setEmailError(false);
                                    }
                                    setEmail(e.target.value)
                                }}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicConfirmEmail">
                                <Form.Control className='form-input' type="email" placeholder="E-Mail bestätigen*" onChange={e => {
                                    if(emailError) {
                                        setEmailError(false);
                                    }
                                    setConfirmEmail(e.target.value)
                                }}/>
                            </Form.Group>
                            <hr />

                            {/* PASSWORD */}
                            <Alert variant="danger" show={passwordError}>{passwordErrorText}</Alert>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Control className='form-input' type="password" placeholder="Passwort*" onChange={e => {
                                    if(passwordError) {
                                        setPasswordError(false);
                                    }
                                    setPassword(e.target.value)
                                }}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
                                <Form.Control className='form-input' type="password" placeholder="Passwort bestätigen*" onChange={e => {
                                    if(passwordError) {
                                        setPasswordError(false);
                                    }
                                    setConfirmPassword(e.target.value)
                                }}/>
                            </Form.Group>

                            <Button variant="secondary" type="submit"><AiOutlineLogin />&#xA0;Registrieren</Button>

                        </Form>
                    </div>
                </div>
            }
        </>
    );
}