import React, { useState } from 'react';
import { logout } from '../auth/PrincipalService';
export default function AppNavbar2({permitted, setToken, setRoles, setPermitted, account, setAccount}) {

    const [mySidebar, setMySidebar] = useState();
    const [overlayBg, setOverlayBg] = useState();

    React.useEffect(() => {
        setMySidebar(document.getElementById("mySidebar"));
        setOverlayBg(document.getElementById("myOverlay"));
      }, [])



    // Toggle between showing and hiding the sidebar, and add overlay effect
    function w3_open() {
        if (mySidebar.style.display === 'block') {
            mySidebar.style.display = 'none';
            overlayBg.style.display = "none";
        } else {
            mySidebar.style.display = 'block';
            overlayBg.style.display = "block";
        }
    }

    function w3_close() {
        mySidebar.style.display = "none";
        overlayBg.style.display = "none";
    }

    const userName = (account != null && account.email != null) ? account.email : "Guest";

    return (
        <div className="w3-light-grey">
            <div className="w3-bar w3-top w3-large" style={{zIndex:4, backgroundColor: "#1b1e22"}}>
                <button className="w3-bar-item w3-button w3-hide-large w3-hover-none w3-hover-text-light-grey" style={{color: "#fefefe"}} onClick={() => w3_open()}><i className="fa fa-bars"></i>  Menu</button>
                <span className="w3-bar-item w3-right" style={{color: "#fefefe"}}>trAmis</span>
            </div>
            <nav className="w3-sidebar w3-collapse w3-white w3-animate-left" style={{zIndex:3, width: "300px"}} id="mySidebar"><br />
                <div className="w3-container w3-row" style={{marginTop: "40px"}}>
                    {/*
                    <div className="w3-col s4">
                        <img src="/w3images/avatar2.png" className="w3-circle w3-margin-right" style={{width:"46px"}} />
                    </div>*/}
                    <div className="w3-col s12 w3-bar">
                        <span>Welcome, <strong>{userName}</strong></span><br />
                        <a href="/" className="w3-bar-item w3-button"><i className="fa fa-envelope"></i></a>
                        <a href="/" className="w3-bar-item w3-button"><i className="fa fa-user"></i></a>
                        <a href="/" className="w3-bar-item w3-button"><i className="fa fa-cog"></i></a>
                    </div>
                </div>
                <hr />
                {/*
                <div className="w3-container">
                    <h5>Dashboard</h5>
                </div> */}
                {permitted ?
                    <div className="w3-bar-block">
                        <a href="/portfolios" className="w3-bar-item w3-button w3-padding"><i className="fa fa-users fa-fw"></i>  Portfolios</a>
                        <a href="/trending-stocks" className="w3-bar-item w3-button w3-padding"><i className="fa fa-eye fa-fw"></i>  Trending-Stocks</a>
                        <a href="/symbols" className="w3-bar-item w3-button w3-padding"><i className="fa fa-users fa-fw"></i>  Symbols</a>
                        <a href="/" className="w3-bar-item w3-button w3-padding" onClick={() => logout({setToken, setRoles, setPermitted, setAccount})}><i className="fa fa-bullseye fa-fw"></i>  Logout</a><br/><br/>
                        <a href="/" className="w3-bar-item w3-button w3-padding w3-hide-large w3-dark-grey w3-hover-black" onClick={() => w3_close()} title="close menu"><i className="fa fa-remove fa-fw"></i>Close Menu</a>
                    </div>
                :
                    <div className="w3-bar-block">
                        <a href="/login" className="w3-bar-item w3-button w3-padding"><i className="fa fa-users fa-fw"></i>  Login</a>
                        <a href="/register" className="w3-bar-item w3-button w3-padding"><i className="fa fa-eye fa-fw"></i>  Register</a>
                        <a href="/" className="w3-bar-item w3-button w3-padding w3-hide-large w3-dark-grey w3-hover-black" onClick={() => w3_close()} title="close menu"><i className="fa fa-remove fa-fw"></i>Close Menu</a>
                    </div>
                }
                
            </nav>

            <div className="w3-overlay w3-hide-large w3-animate-opacity" onClick={() => w3_close()} style={{cursor:"pointer"}} title="close side menu" id="myOverlay" />

        </div>
    )
}