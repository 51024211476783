import {useState} from "react";
import {Badge} from "react-bootstrap";
import SymbolMetaData from "./SymbolMetaData";
import {Link} from "react-router-dom";

export default function SymbolCard(props) {
    let [symbol] = useState(props.symbol);
    let [detailView, setDetailView] = useState(false);

    return (
        <>
            {detailView ?
                <div className="symbol-card-detail" onClick={() => setDetailView(false)}>
                    <span>Symbol:</span>
                    <br />
                    <span style={{fontWeight: "bold"}}>{symbol.symbol}</span>
                    <br />
                    <span>Beschreibung:</span>
                    <br />
                    <span style={{fontWeight: "bold"}}>{symbol.description}</span>
                    <br />
                    <span>Währung:</span>
                    <br />
                    <span style={{fontWeight: "bold"}}>{symbol.currency}</span>
                    <br />
                    <span>Figi:</span>
                    <br />
                    <span style={{fontWeight: "bold"}}>{symbol.figi}</span>
                    <br />
                    <span>Mic:</span>
                    <br />
                    <span style={{fontWeight: "bold"}}>{symbol.mic}</span>
                </div>
                :
                <div className="symbol-card-front">
                    <Link to={'/company/' + symbol.displaySymbol} style={{textDecoration: "none", color: '#4e8abb'}}>
                        <div className="symbol-card-front-header">
                            <span className="symbol-card-front-header-symbol" onClick={() => props.loadSymbol(symbol)}>{symbol.displaySymbol}</span>
                            <br/>
                            <br/>
                            <span style={{fontWeight: "bold"}}>{symbol.description}</span>
                            <br/>
                            <br/>
                            <span>
                            <Badge variant={
                                symbol.type === SymbolMetaData.SYMBOL_TYPES[0] ? "primary" :
                                    symbol.type === SymbolMetaData.SYMBOL_TYPES[1] ? "secondary" :
                                        symbol.type === SymbolMetaData.SYMBOL_TYPES[2] ? "success" :
                                            symbol.type === SymbolMetaData.SYMBOL_TYPES[3] ? "danger" :
                                                symbol.type === SymbolMetaData.SYMBOL_TYPES[4] ? "warning" : "light"
                            }>{symbol.type}</Badge>
                        </span>
                        </div>
                    </Link>

                    <div className="symbol-card-front-body" onClick={() => setDetailView(true)}>
                        Weitere Details
                    </div>
                </div>
            }
        </>
    );
}
