import PropTypes from 'prop-types';
import {useState} from "react";
import {Button, Form} from "react-bootstrap";
import {loginUser} from "./PrincipalService";
import {AiOutlineLogin} from "react-icons/ai";
import {Redirect} from "react-router";
import Strings from "../config/Strings";
import {Alert} from "react-bootstrap";
import {GetTramisResource} from "../clients/TramisClient";
import './AuthStyle.css';

export default function Authentication({ setToken, setRoles, setPermitted, setAccount }) {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [authenticated, setAuthenticated] = useState(false);
    const [error, setError] = useState(false);

    const handleSubmit = async e => {

        e.preventDefault();
        let response = await loginUser(
            email,
            password
        );

        if (!response.error) {
            //Token is available
            let token = response.data;

            let saved = {accessToken: token.token, secD: token.secD, perD: token.perD};

            //Now save the token
            setToken(saved);
            setRoles(saved);
            setPermitted(saved);

            if (saved != null && saved.perD != null) {
                const account = JSON.parse(atob(saved.perD));
                setAccount(account);
            }

            setAuthenticated(true);

            //Set the roles
            GetTramisResource('api/auth/me', saved).then(r => {
                if (!r.error && r.result != null && r.result.roles != null) {
                    setRoles(r.result.roles.map(r => r.name));
                }
            });
        } else {
            setError(true);
        }
    }

    return (
        <>
            {authenticated ?
                <>
                    <Redirect to={"/dashboard"}/>
                </>
                :
                <div className="w3-main" style={{marginLeft: "300px", marginTop:"43px"}}>

                    {/* Header */}
                    <header className="w3-container" style={{paddingTop:"50px"}} />

                    <div className="w3-container authentication-form">
                        <h3>{Strings.AUTHENTICATION_TITLE}</h3>
                        <hr/>
                        <Form style={{marginTop: "25px"}}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Control className='form-input' type="email" placeholder={Strings.AUTHENTICATION_EMAIL} value={email}
                                            onChange={e => {
                                                setEmail(e.target.value);
                                                setError(false);
                                            }}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Control className='form-input' type="password" placeholder={Strings.AUTHENTICATION_PASSWORD} value={password}
                                            onChange={e => {
                                                setPassword(e.target.value);
                                                setError(false)}}/>
                            </Form.Group>

                            <Alert variant="danger" show={error} onClick={() => setError(false)} style={{padding: "10px"}}>
                                Falsche E-Mail-Adresse/falsches Passwort.
                            </Alert>
                            <Button variant="secondary" style={{cursor: 'pointer'}} type="submit" onClick={(e) => handleSubmit(e)}><AiOutlineLogin/>&#xA0;{Strings.NAVBAR_SIGN_IN}</Button>
                        </Form>
                    </div>
                </div>
            }

        </>
    )
}

Authentication.propTypes = {
    setToken: PropTypes.func.isRequired
}