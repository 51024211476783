import {Table} from "react-bootstrap";

export function convertTimestampToLocalDate(timestamp) {
    //Convert the timestamp to date
    const a = new Date(timestamp * 1000);

    //Now convert it to datetime
    let year = a.getFullYear();

    let month = a.getMonth() + 1;
    if(month < 10) {
        month = '0' + month;
    }

    let date = a.getDate();
    if(date < 10) {
        date = '0' + date;
    }

    let hour = a.getHours();
    if(hour < 10) {
        hour = '0' + hour;
    }

    let min = a.getMinutes();
    if(min < 10) {
        min = '0' + min;
    }

    let sec = a.getSeconds();
    if(sec < 10) {
        sec = '0' + sec;
    }

    return year + '-' + month + '-' + date + 'T' + hour + ':' + min + ':' + sec ;
}

export function convertLocalDateTimeToReadableString(datetime, withTime = true) {
    let date = new Date(datetime);
    const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    const month = (date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
    const hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    const minute = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    const second = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds(); 
    if(withTime) {
        return day + "." + month + "." + date.getFullYear() + " um " + hour + ":" + minute + ":" + second;
    } else {
        return day + "." + month + "." + date.getFullYear();
    }
}

export function convertCandleDataIntoApexForm(data) {
    let convertedData = [];

    for(let i = 0; i < data.length; i++) {
        let entry = {};
        entry.x = data[i].timestamp.replace("T", " ");
        entry.y = [data[i].openingPrice, data[i].highPrice, data[i].lowPrice, data[i].closePrice];
        convertedData.push(entry);
    }
    return convertedData;
}

export function convertAnalysisDataToLineApexForm(data, nameOfValues) {
    let convertedData = [];

    for(let i = 0; i < data.timestamps.length; i++) {
        let entry = {};
        entry.x = data.timestamps[i].replace("T", " ");
        entry.y = data[nameOfValues][i];
        convertedData.push(entry);
    }
    return convertedData;
}
export function convertAnalysisArrayToTable(data) {
    //Get an array of unique dates
    let arrayOfUniqueDates = [];
    for(let i = 0; i < data.length; i++) {
        if(!arrayOfUniqueDates.includes(data[i].rangeFrom)) {
            arrayOfUniqueDates.push(data[i].rangeFrom);
        }
    }

    //Get the max amount of entries for each column
    let maxEntries = 0;
    for(let i = 0; i < arrayOfUniqueDates.length; i++) {
        let entriesOfThatDate = data.filter(entry => entry.rangeFrom === arrayOfUniqueDates[i]);
        if(entriesOfThatDate.length > maxEntries) {
            maxEntries = entriesOfThatDate.length;
        }
    }

    let rows = [];

    //Iterate over all rows
    for(let i = 0; i < maxEntries; i++) {
        let row = {};
        for(let j = 0; j < arrayOfUniqueDates.length; j++) {
            for(let k = 0; k < data.length; k++) {
                if(data[k].rangeFrom === arrayOfUniqueDates[j]) {
                    row[arrayOfUniqueDates[j]] = data[k];
                    data.splice(k, 1);

                    //Just to make things sure...
                    k = data.length;
                } else {
                    row[arrayOfUniqueDates[j]] = "";
                }
            }
        }
        rows.push(row);
    }

    return(
        <Table bordered style={{borderColor: '#000000'}}>
            <thead>
                <tr style={{color: "black"}}>
                    {arrayOfUniqueDates.map(e => (
                        <th style={{textAlign: "center", color: '#4e8abb', backgroundColor: '#191d22'}}>{convertLocalDateTimeToReadableString(e, false)}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
            {rows.map(row => (
                <tr role="row" style={{color: "black"}}>
                    {Object.keys(row).map(c => (
                        convertAnalysisEntry(row[c])
                    ))}
                </tr>
            ))}
            </tbody>
        </Table>
    )
}

function convertAnalysisEntry(entry) {
    //Calculate the background color
    let backgroundColor = entry.trend === 'DECREASING' ? '#ef403c' : (entry.trend === 'INCREASING' ? '#00b746' : (entry.trend === 'STAGNATING' ? '#fcdc5d' : '#252830'))

    //Convert the name
    let name = entry.matchingPattern != null ? entry.matchingPattern.replace("_", " ") : "";
    if(name.length > 0) {
        name = name.toLowerCase();
        name = name[0].toUpperCase() + name.substring(1, name.length);
    }

    return(
        <td style={{backgroundColor: backgroundColor, textAlign: "center"}}>
            <span style={{fontWeight: "bold"}}>{name}</span>
            <br />
            {entry.probability} {entry.probability != null ? "%" : ""}
        </td>
    )
}

