import {getToken} from "../auth/TokenStorageService";
import GlobalConstants from "../config/GlobalConstants";

export async function GetAllTramisResources(url) {
    const token = getToken();
    let errorOccurred = false;

    return fetch(GlobalConstants.TRAMIS_CLIENT + url, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken,
        }
    })
        .then(function (response) {
            if(!response.ok) {
                errorOccurred = true;
            }
            return response.json();
        })
        .then(function (response) {
            if(errorOccurred) {
                return {error: true, message: "An error occurred.CODE: " + response.errorCode + " - CAUSE: " + response.info, result: []}
            } else {
                return {error: false, message: "", result: response}
            }
        })
        .catch(function (error) {
            return {error: true, message: "An unknown error occurred: " + error, result: []}
        });
}

export async function GetFilteredTramisResources(url, filter) {
    const token = getToken();
    let errorOccurred = false;

    return fetch(GlobalConstants.TRAMIS_CLIENT + url + createFilterQuery(filter), {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken,
        }
    })
        .then(function (response) {
            if(!response.ok) {
                errorOccurred = true;
            }
            return response.json();
        })
        .then(function (response) {
            if(errorOccurred) {
                return {error: true, message: "An error occurred.CODE: " + response.errorCode + " - CAUSE: " + response.info, result: []}
            } else {
                return {error: false, message: "", result: response}
            }
        })
        .catch(function (error) {
            return {error: true, message: "An unknown error occurred: " + error, result: []}
        });
}

export async function GetTramisResource(url, customToken = null) {
    let token;
    if (customToken != null) {
        token = customToken;
    } else {
        token = getToken();
    }
    let errorOccurred = false;

    return fetch(GlobalConstants.TRAMIS_CLIENT + url, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken,
        }
    })
        .then(function (response) {
            if(!response.ok) {
                errorOccurred = true;
            }
            return response.json();
        })
        .then(function (response) {
            if(errorOccurred) {
                return {error: true, message: "An error occurred.CODE: " + response.errorCode + " - CAUSE: " + response.info, result: {}}
            } else {
                return {error: false, message: "", result: response}
            }
        })
        .catch(function (error) {
            return {error: true, message: "An unknown error occurred: " + error, result: {}}
        });
}

export async function CreateTramisResource(url, resource) {
    let token = getToken();
    let errorOccurred = false;

    return fetch(GlobalConstants.TRAMIS_CLIENT + url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(resource),
    }).then(function (response) {
        if(!response.ok) {
            errorOccurred = true;
        }
        return response.json();
    })
        .then(function (response) {
            if(errorOccurred) {
                return {error: true, message: "An error occurred.CODE: " + response.errorCode + " - CAUSE: " + response.info, result: {}}
            } else {
                return {error: false, message: "", result: response}
            }
        })
        .catch(function (error) {
            return {error: true, message: "An unknown error occurred: " + error, result: {}}
        });
}

export async function UpdateTramisResource(url, resource) {
    const token = getToken();
    let errorOccurred = false;

    resource["updatedBy"] = token.id;

    return fetch(GlobalConstants.TRAMIS_CLIENT + url, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(resource),
    }).then(function (response) {
        if(!response.ok) {
            errorOccurred = true;
        }
        return response.json();
    }).then(function (response) {
        if(errorOccurred) {
            return {error: true, message: "Fehler beim Update der Resource. CODE: " + response.errorCode + " - URSACHE: " + response.info, result: {}}
        } else {
            return {error: false, message: "", result: response}
        }
    }).catch(function (error) {
        return {error: true, message: "Ein unbekannter Fehler ist aufgetreten.", result: error}
    });
}

export async function DeleteTramisResource(url) {
    const token = getToken();
    let errorOccurred = false;

    return fetch(GlobalConstants.TRAMIS_CLIENT + url, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken,
            'Content-Type': 'application/json'
        },
    }).then(function (response) {
        if(!response.ok) {
            errorOccurred = true;
        }
        return response;
    }).then(function (response) {
        if(errorOccurred) {
            return {error: true, message: "Fehler beim Löschen der Resource. CODE: " + response.errorCode + " - URSACHE: " + response.info, result: {}}
        } else {
            return {error: false, message: "", result: response}
        }
    }).catch(function (error) {
        return {error: true, message: "Ein unbekannter Fehler ist aufgetreten.", result: error}
    });
}

function createFilterQuery(filter) {

    //Return an empty string if the filter is empty
    if(filter == null) {
        return "";
    }

    //Create a query otherwise
    let query = "?";

    Object.keys(filter).forEach((key, i) => {
        if(filter[key] != null && filter[key] !== "") {

            //Check for the data type of the filter param
            if(Array.isArray(filter[key])) {
                //TYPE = Array -> Transform Array ["A","B","C",...] to single String "A,B,C,"
                let entries = "";
                filter[key].forEach(entry => (entries = entries + "," + entry));
                if (entries.slice(-1) === ",") {
                    entries = entries.slice(0, -1);
                }
                if (filter[key].length > 0) {
                    query = query + key + "=" + entries + "&";
                }
            } else {
                //TYPE = Default
                query = query + key + "=" + filter[key] + "&";
            }
        }
    });

    //Remove the last character if its a '&' or '?'
    const lastChar = query.slice(-1);
    if(lastChar === '&' || lastChar === '?' ){
        query = query.slice(0,-1);
    }

    //URI Encoding
    query = encodeURI(query);
    return query;
}