import { AiOutlineCloudSync } from "react-icons/ai";
import { FcAddDatabase } from "react-icons/fc";
import { GoPencil} from "react-icons/go";
import { TiDeleteOutline } from "react-icons/ti";
import { VscDashboard } from "react-icons/vsc";
import { BsGraphUp} from "react-icons/bs";
import Strings from "../config/Strings";
import {
    AddItemToWatchlist,
    CreateWatchlist,
    GetFilteredWatchlists,
    RemoveItemFromWatchlist,
    UpdateWatchlist
} from "../watchlists/WatchlistService";
import {Button, Col, Dropdown, DropdownButton, Form, InputGroup, Spinner} from "react-bootstrap";
import {Label} from "reactstrap";
import {withToast} from "../util/ToastService";
import {convertLocalDateTimeToReadableString} from "../util/ConverterUtil";
import {GetTramisResource} from "../clients/TramisClient";
import PurchaseDetails from "./PurchaseDetails";
import ReactApexChart from "react-apexcharts";
import './../../App.css';
import React from "react";
import PurchasesList from "./PurchasesList";
import PurchaseItemBuyModal from "./PurchaseItemBuyModal";

class Portfolios extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            token: props.token,
            accountId: props.accountId,
            watchlists: [],
            selectedWatchlist: {
                id: null,
                watchlistItems: [],
            },
            purchaseDetails: null,
            positiveStocks: [],
            negativeStocks: [],
            inactiveStocks: [],
            selectedPeriodResult: null,
            expandedDiagrams: [],
            recommendations: [],
            currentPeriod: true,
            filterPeriodStart: null,
            filterPeriodEnd: null,
            filteredResults: [],
            analysisResults: [],
            candleFilter: {
                symbol: null,
                resolution: "DAY",
                highPass: 30,
            },
            showDiagrams: true,
            selectedWatchlistItem: {},
            performScheduledAnalysis: true,

            createOrEditWatchlist: false,
            addItem: false,
            synchronizing: false,
            showBuyItemModal: false
        }
    }

    async componentDidMount() {
        let watchlists = await this.loadWatchlists();
        if(watchlists.length > 0) {
            await this.changeWatchlist(watchlists[0]);
        }

    }

    render() {
        if (this.state.loading) {
            return (
                <div className="board-content-box" style={{textAlign: "center"}}>
                    <Spinner animation="border" />
                    <br />
                    Lade...
                </div>
            );
        }

        if (this.state.createOrEditWatchlist) {
            return (
                <div className="w3-content w3-padding" style={{maxWidth: "360px", marginTop: "20px"}}>
                    <div className="board-title">
                        {this.state.selectedWatchlist.id == null ? "Neue Watchlist+" : this.state.selectedWatchlist.name + " bearbeiten"}
                    </div>
                    <div className="board-content-box">
                        <Form>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Label>Name</Label>
                                    <Form.Control className='form-input' type="text" value={this.state.selectedWatchlist.name}
                                                    onChange={(e) => {
                                                    e.persist();
                                                    this.setState(prevState => ({
                                                        ...prevState,
                                                        selectedWatchlist: {
                                                            ...prevState.selectedWatchlist,
                                                            name: e.target.value
                                                        }
                                                    }))}}/>
                                </Form.Group>
                            </Form.Row>
                            <br/>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Label>Beschreibung</Label>
                                    <Form.Control className='form-input' as="textarea" rows="5"
                                                    value={this.state.selectedWatchlist.description}
                                                    onChange={(e) => {
                                                    e.persist();
                                                    this.setState(prevState => ({
                                                        ...prevState,
                                                        selectedWatchlist: {
                                                            ...prevState.selectedWatchlist,
                                                            description: e.target.value
                                                        }
                                                    }))}}/>
                                </Form.Group>
                            </Form.Row>
                        </Form>
                        {this.state.selectedWatchlist.watchlistItems.length > 0 &&
                        <>
                            <hr/>
                            <div>
                                {this.state.selectedWatchlist.watchlistItems.map(item => (
                                    this.editWatchListItem(item)
                                ))}
                            </div>
                        </>
                        }
                        <div style={{marginTop: "20px"}}>
                            <Button variant={"danger"}
                                    onClick={() => this.loadWatchlists().then(r => this.updateState('createOrEditWatchlist', false))}>Abbrechen</Button>
                            <span style={{float: "right"}}>
                                <Button variant="success" onClick={() => this.saveWatchlist()}>Speichern</Button>
                            </span>
                        </div>
                    </div>
                </div>
            )
        }

        if (this.state.addItem) {
            return (
                <div className="w3-content w3-padding" style={{maxWidth: "360px", marginTop: "20px"}}>
                    <div className="board-title">
                        Symbol hinzufügen+
                    </div>
                    <div className="board-content-box">
                        <Form>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Label>Symbol</Label>
                                    <InputGroup>
                                        <Form.Control className="form-input" type="text" value={this.state.selectedWatchlistItem.symbol}
                                                        onChange={(e) => {
                                                        e.persist();
                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            selectedWatchlistItem: {
                                                                ...prevState.selectedWatchlistItem,
                                                                symbol: e.target.value
                                                            }
                                                        }))}}/>
                                        <InputGroup.Append>
                                            <Button variant="outline-secondary"
                                                    onClick={() => this.checkSymbol(this.state.selectedWatchlistItem.symbol)}><AiOutlineCloudSync/> Check</Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Form.Group>
                            </Form.Row>
                        </Form>
                        <div style={{marginTop: "20px"}}>
                            <Button variant={"danger"}
                                    onClick={() => this.updateState('addItem', false)}>Abbrechen</Button>
                            <span style={{float: "right"}}>
                                <Button variant="success" onClick={() => this.saveWatchlistItem()}>Speichern</Button>
                            </span>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="w3-main" style={{marginLeft: "300px", marginTop:"43px"}}>

                {/* Header */}
                <header className="w3-container" style={{paddingTop:"50px"}}>
                    <h2><VscDashboard/>&#xA0;Portfolio</h2>
                </header>

                <PurchaseItemBuyModal show={this.state.showBuyItemModal} 
                    onHide={() => this.updateState('showBuyItemModal', false)} 
                    onReloadWatchlist={() => this.changeWatchlist(this.state.selectedWatchlist)}
                    accountId={this.state.accountId} 
                    watchlistId={this.state.selectedWatchlist.id}/>

                <div className="w3-container board-content-box">
                {this.state.watchlists.length > 0 ?
                            <>
                                <div style={{width: "100%", height: "50px", fontSize: "20px"}}>
                                    <span style={{float: "left"}}>
                                        <DropdownButton variant="outline-secondary"
                                                        title={this.state.selectedWatchlist.name}>
                                        {this.state.watchlists.map(s => (
                                            <Dropdown.Item onClick={() => {
                                                this.changeWatchlist(s).then(r => r);
                                            }}>{s.name}</Dropdown.Item>
                                        ))}
                                            <Dropdown.Divider/>
                                        <Dropdown.Item onClick={() => {
                                            this.updateState('selectedWatchlist', {
                                                accountId: this.state.accountId,
                                                watchlistItems: []
                                            });
                                            this.updateState('createOrEditWatchlist', true);
                                        }}>Neue Watchlist+</Dropdown.Item>
                                        </DropdownButton>
                                    </span>
                                    <span style={{float: "right"}}>
                                        <Button variant="outline-secondary" onClick={() => {
                                            this.updateState('showBuyItemModal', true);
                                        }}>+</Button>
                                        <Button style={{marginLeft: "10px"}} variant="secondary"
                                                onClick={() => this.updateState('createOrEditWatchlist', true)}><GoPencil/></Button>
                                    </span>
                                </div>
                                <hr/>

                                {/* PURCHASE DETAILS SECTION */}
                                <PurchaseDetails purchaseDetails={this.state.purchaseDetails} />

                                {/* PURCHASE ITEMS */}
                                <PurchasesList positiveStocks={this.state.positiveStocks} negativeStocks={this.state.negativeStocks} inactiveStocks={this.state.inactiveStocks}/>
                            </>
                            :
                            <div style={{
                                border: "solid 1px #282c34",
                                backgroundColor: '#282c34',
                                borderRadius: "20px",
                                padding: "10px",
                                cursor: "pointer",
                                maxMidth: "360px",
                                margin: "auto",
                                textAlign: "center"
                            }}
                                 onClick={() => {
                                     this.updateState('createOrEditWatchlist', true);
                                     this.updateState('selectedWatchlist', {
                                         accountId: this.state.accountId,
                                         name: "",
                                         description: "",
                                         watchlistItems: []
                                     });
                                 }}
                            >
                                <FcAddDatabase size={16}/>
                                <br/>
                                <span style={{fontSize: "18px"}}>{Strings.DASHBOARD_NO_WATCHLIST}</span>
                            </div>
                        }
                </div>
            </div>
        );
    }


    //-------------
    // API Methods
    //-------------
    async loadRecommendations(watchlistId) {
        let response = await GetTramisResource('api/accounts/' + this.state.accountId + '/watchlists/' + watchlistId + '/recommendations');
        if (!response.error) {
            this.updateState('recommendations', response.result);
        }
    }

    async checkSymbol(symbol) {
        let exists = false;
        let response = await GetTramisResource('api/symbols/search/by-symbol?symbol=' + symbol);
        if(!response.error) {
            if(response.result.length > 0) {
                exists = true;
            }
        }

        if(exists) {
            this.props.addToast("Symbol existiert und ist gültig.", {
                appearance: "success",
                autoDismiss: true
            });
        } else {
            this.props.addToast("Symbol wurde nicht gefunden/es existieren keine Daten.", {
                appearance: "error",
                autoDismiss: true
            });
        }
    }
    
    async saveWatchlist(){
        let watchlist;
        if(this.state.selectedWatchlist.id != null) {
            //Update
            watchlist = await UpdateWatchlist(this.state.selectedWatchlist);
        } else {
            //Create
            watchlist = await CreateWatchlist(this.state.selectedWatchlist);
        }
        if(!watchlist.error) {
            await this.loadWatchlists().then(r => this.updateState('createOrEditWatchlist', false));
        }
    }

    async saveWatchlistItem() {
        let item;
        if(this.state.selectedWatchlistItem.id != null) {
            //Update
        } else {
            //Create
            item = await AddItemToWatchlist(this.state.accountId, this.state.selectedWatchlistItem);
            if(!item.error) {
                await this.loadWatchlists().then(r => this.updateState('addItem', false));
            }
        }
    }

    async removeWatchlistItem(item) {
        let response = await RemoveItemFromWatchlist(this.state.accountId, item);
        if(!response.error) {
            //Reload the watchlist
            let items = this.state.selectedWatchlist.watchlistItems;
            items = items.filter(i => i.id !== item.id);
            this.setState(prevState => ({...prevState, selectedWatchlist: {...prevState.selectedWatchlist, watchlistItems: items}}));
        }
    }

    async loadWatchlists() {
        if (this.state.accountId == null) {
            return;
        }
        let response = await GetFilteredWatchlists(this.state.accountId);
        if(!response.error) {
            this.updateState('watchlists', response.result);
            if(response.result.length > 0) {
                this.changeWatchlist(response.result[0])
            }
            return response.result;
        } else {
            return [];
        }
    }

    async changeWatchlist(watchlist) {
        await this.loadWatchlistPurchaseDetails(watchlist.id);
        await this.loadRecommendations(watchlist.id);
        this.updateState('selectedWatchlist', watchlist);
    }

    async loadWatchlistPurchaseDetails(watchlistId) {
        this.setState(prevState => ({...prevState, loading: true}));
        let response = await GetTramisResource('api/accounts/' + this.state.accountId + '/watchlists/' + watchlistId + '/purchases');
        if (!response.error) {
            if (response.result != null) {
                const purchaseDetails = response.result;
                const positiveStocks = purchaseDetails.uptrendPurchases;
                const negativeStocks = purchaseDetails.downtrendPurchases;
                const inactiveStocks = purchaseDetails.inactivePurchases;
                this.setState(prevState => ({
                    ...prevState, 
                    purchaseDetails: purchaseDetails,
                    positiveStocks: positiveStocks,
                    negativeStocks: negativeStocks,
                    inactiveStocks: inactiveStocks, 
                    loading: false}));
            } else {
                this.setState(prevState => ({
                    ...prevState, 
                    purchaseDetails: null, 
                    positiveStocks: [],
                    negativeStocks: [],
                    inactiveStocks: [],
                    filteredResults: [], 
                    loading: false}));
            }
        } else {
            this.setState(prevState => (
                {...prevState, 
                purchaseDetails: null, 
                positiveStocks: [],
                negativeStocks: [],
                inactiveStocks: [],
                filteredResults: [], 
                loading: false}));
        }
    }

    //--------
    // HELPERS
    //--------

    formattedRecommendation(prevPeriodValue, currentPeriodValue, reversedColor) {
        const difference = currentPeriodValue - prevPeriodValue;
        const vorzeichen = difference < 0 ? "" : "+";
        let color = null;
        if (reversedColor) {
            if (difference >= 0) {
                color = "red";
            } else {
                color = "green";
            }
        } else {
            if (difference < 0) {
                color = "red";
            } else {
                color = "green";
            }
        }
        if (this.state.currentPeriod) {
            return <><span style={{fontSize: "22px"}}>{currentPeriodValue}</span> {difference !== 0 &&<>(<span style={{color: color}}>{vorzeichen}{difference}</span>)</>}</>
        } else {
            return <><span style={{fontSize: "22px"}}>{prevPeriodValue}</span></>
        }
    }

    printCounts(recommendation) {
        const currentTotalCount = recommendation.strongBuyCount + 
            recommendation.buyCount + 
            recommendation.holdCount + 
            recommendation.sellCount + 
            recommendation.strongSellCount;
        const prevTotalCount = recommendation.prevStrongBuyCount + 
            recommendation.prevBuyCount + 
            recommendation.prevHoldCount + 
            recommendation.prevSellCount + 
            recommendation.prevStrongSellCount;
        const difference = currentTotalCount - prevTotalCount;
        const vorzeichen = difference < 0 ? "" : "+";
        let color = null;
        if (difference < 0) {
            color = "red"
        } else {
            color = "green"
        }
        if (this.state.currentPeriod) {
            return <><span style={{fontSize: "22px"}}>{currentTotalCount}</span> {difference !== 0 && <>(<span style={{color: color}}>{vorzeichen}{difference}</span></>})</>
        } else {
            return <><span style={{fontSize: "22px"}}>{prevTotalCount}</span></>
        }
    }
    
    printScore(recommendation) {
        const difference = recommendation.score - recommendation.prevScore;
        const vorzeichen = difference < 0 ? "" : "+";
        let color = null;
        if (difference < 0) {
            color = "red"
        } else {
            color = "green"
        }
        if (this.state.currentPeriod) {
            return <><span style={{fontSize: "24px"}}><b>{recommendation.score}</b></span> {difference !== 0 && <>(<span style={{color: color}}>{vorzeichen}{difference}</span>)</>}</>
        } else {
            return <><span style={{fontSize: "24px"}}><b>{recommendation.prevScore}</b></span></>
        }
    }

    printSignal(recommendation) {
        const signal = this.state.currentPeriod ? recommendation.signal : recommendation.prevSignal
        if (signal === "STRONG_BUY") {
            return <span style={{backgroundColor: "#1ca401", color: "#fefefe", padding: "5px 5px", borderRadius: "5px"}}>Strong Buy</span>
        } else if (signal === "BUY") {
            return <span style={{backgroundColor: "#24d800", color: "#fefefe", padding: "5px 5px", borderRadius: "5px"}}>Buy</span>
        } else if (signal === "HOLD") {
            return <span style={{backgroundColor: "#ecec00", color: "#fefefe", padding: "5px 5px", borderRadius: "5px"}}>Hold</span>
        } else if (signal === "SELL") {
            return <span style={{backgroundColor: "#ec8f00", color: "#fefefe", padding: "5px 5px", borderRadius: "5px"}}>Sell</span>
        } else if (signal === "STRONG_SELL") {
            return <span style={{backgroundColor: "#d90a03", color: "#fefefe", padding: "5px 5px", borderRadius: "5px"}}>Strong Sell</span>
        } else {
            return <span style={{backgroundColor: "black", color: "#fefefe", padding: "5px 5px", borderRadius: "5px"}}>Unknown</span>
        }
    }

    findMatchingPurchasePeriodResults(symbol) {
        let symbolResults = [];
        for (let i = 0; i < this.state.filteredResults.length; i++) {
            let matchingResults = this.state.filteredResults[i].symbolResults.filter(r => r.symbol === symbol);
            let isLast = this.state.filteredResults[i].isLast;
            for (let j = 0; j < matchingResults.length; j++) {
                symbolResults.push({...matchingResults[j], isLast: isLast});
            }
        }
        return symbolResults;
    }

    printRecommendationRow(recommendation) {
        let series = [];
        const strongBuyCount = this.state.currentPeriod ? recommendation.strongBuyCount : recommendation.prevStrongBuyCount;
        const buyCount = this.state.currentPeriod ? recommendation.buyCount : recommendation.prevBuyCount;
        const holdCount = this.state.currentPeriod ? recommendation.holdCount : recommendation.prevHoldCount;
        const sellCount = this.state.currentPeriod ? recommendation.sellCount : recommendation.prevSellCount;
        const strongSellCount = this.state.currentPeriod ? recommendation.strongSellCount : recommendation.prevStrongSellCount;
        series.push(strongBuyCount);
        series.push(buyCount);
        series.push(holdCount);
        series.push(sellCount);
        series.push(strongSellCount);
        const options = {
            chart: {
              type: 'donut',
              width: '100px',
              height: '100px'
            },
            colors: ["#2bc336", "#3af448", "#f4ee3a", "#f4a23a", "#f4423a"],
            labels: ["Strong Buy: " + strongBuyCount, "Buy: " + buyCount, "Hold: " + holdCount, "Sell: " + sellCount, "Strong Sell: " + strongSellCount]
          }
        return (
            <tr  style={{backgroundColor: "#fefefe"}} role="row" key={recommendation.id}>
                <td style={{textAlign: "center", fontSize: "24px", fontWeight: "bold"}}>{recommendation.symbol}</td>
                <td><ReactApexChart width={350} height={350} options={options} series={series} type="donut" /></td>
                <td>{this.printCounts(recommendation)}</td>
                <td>{this.printScore(recommendation)}</td>
                <td>{this.printSignal(recommendation)}</td>
            </tr>
        )
    }

    printWatchlistItemRow(item) {
        const matchingPeriodResults = this.findMatchingPurchasePeriodResults(item.symbol);
        if (matchingPeriodResults.length > 0) {
            let startPrice = matchingPeriodResults[0].startPrice;
            let currentPrice = matchingPeriodResults[matchingPeriodResults.length - 1].endPrice;
            let priceChange = currentPrice - startPrice;
            let priceChangePerc = (100 * currentPrice/startPrice) - 100;
            let vorzeichen = priceChange < 0 ? "" : "+";
            let color = priceChange < 0 ? "red" : "green";
            //Diagram
            const diagramOptions = this.createChartOptions(matchingPeriodResults);
            const diagramSeries = this.createChartSeries(matchingPeriodResults);
            let isExpanded = this.state.expandedDiagrams.includes(item.id);
            return (
                <>
                    <tr style={{backgroundColor: "#fefefe"}} role="row" key={item.id}>
                        <td style={{fontSize: "24px", textAlign: "center", fontWeight: "bold"}}>{item.symbol}</td>
                        <td><span style={{fontSize: "22px"}}>{startPrice.toFixed(2)} $</span></td>
                        <td><span style={{fontSize: "22px"}}>{currentPrice.toFixed(2)} $</span></td>
                        <td><span style={{fontSize: "22px", color: color}}>{priceChange.toFixed(2)} $</span> (<span style={{color: color}}>{vorzeichen}{priceChangePerc.toFixed(2)}%</span>)</td>
                        <td onClick={() => this.toggleDiagram(item)} style={{cursor: "pointer"}}>
                            <BsGraphUp size={24} color={isExpanded ? "#4e8abb" : "#15446a"}/>
                        </td>
                        <td style={{textAlign: "right"}}>
                            <Button variant="danger">Tracking beenden</Button>
                        </td>
                    </tr>
                    {isExpanded &&
                        <tr style={{backgroundColor: "#F4F4F4"}} role="row">
                            <td colSpan={6}>
                                {
                                    <ReactApexChart options={diagramOptions} series={diagramSeries} type="rangeArea"
                                    height={350}/>
                                }
                            </td>
                        </tr>
                    }
                </>
            )
        }
        return (
            <tr style={{backgroundColor: "#fefefe"}} role="row" key={item.id}>
                <td style={{fontSize: "24px", textAlign: "center", fontWeight: "bold"}}>{item.symbol}</td>
                <td colSpan={4}>
                    Für dieses Symbol wurde das Tracking noch nicht gestartet
                </td>
                <td>
                    <Button variant="success">Tracking starten</Button>
                </td>  
            </tr>
        )
    }

    toggleAll() {
        let items = this.state.selectedWatchlist.watchlistItems;
        let expanded = this.state.expandedDiagrams;
        if (items.length === expanded.length) {
            expanded = [];
        } else {
            items.forEach(i => {
                if (!expanded.includes(i.id)) {
                    expanded.push(i.id)
                }
            });
        }
        this.setState(prevState => ({...prevState, expandedDiagrams: expanded}));
    }

    toggleDiagram(item) {
        let expandedDiagrams = this.state.expandedDiagrams;
        if (expandedDiagrams.includes(item.id)) {
            expandedDiagrams = expandedDiagrams.filter(i => i !== item.id);
        } else {
            expandedDiagrams.push(item.id);
        }
        this.setState(prevState => ({...prevState, expandedDiagrams: expandedDiagrams}));
    }
    createChartOptions(matchingPeriodResults) {
        let timestamps = [];
        let yMin = 10000000;
        let yMax = 0;
        const startPrice = matchingPeriodResults.length > 0 ? matchingPeriodResults[0].startPrice : 0;
        const endPrice = matchingPeriodResults.length > 0 ? matchingPeriodResults[matchingPeriodResults.length - 1].endPrice : 0;
        const isPositive = endPrice >= startPrice;
        for (let i = 0; i < matchingPeriodResults.length; i++) {
            let result = matchingPeriodResults[i];
            if (result.startPrice > yMax) {
                yMax = result.startPrice
            }
            if (result.startPrice < yMin) {
                yMin = result.startPrice;
            }
            timestamps.push(convertLocalDateTimeToReadableString(result.periodStart + "T00:00:00Z", false));

            if (result.isLast) {
                if (result.endPrice > yMax) {
                    yMax = result.endPrice;
                }
                if (result.endPrice < yMin) {
                    yMin = result.endPrice;
                }
                timestamps.push(convertLocalDateTimeToReadableString(result.periodEnd + "T00:00:00Z", false));
            }
        }
        const yMaxOffset = yMax <= startPrice ? 0 : (yMax - startPrice)/10;
        yMax += yMaxOffset;
        const yMinOffset = yMin >= startPrice ? 0 : (startPrice - yMin)/10;
        yMin -= yMinOffset;
        const color = isPositive ? '#23b125' : '#bf052d';
        let options = {
            chart: {
                height: 350,
                type: 'rangeArea',
                animations: {
                  speed: 500
                },
                foreColor: "#343434"
              },
              colors: [color, '#343434'],
              dataLabels: {
                enabled: true,
                enabledOnSeries: [0],
              },
              fill: {
                opacity: [0.7, 1]
              },
              stroke: {
                curve: 'smooth',
                width: [0, 2]
              },
 
              markers: {
                hover: {
                  sizeOffset: 5
                }
              },
              xaxis: {
                labels: {
                    style: {
                        fontSize: "16px"
                    }
                }
              },
              yaxis: {
                min: yMin,
                max: yMax,
                tickAmount: 5,
                labels: {
                    style: {
                        fontSize: "16px"
                    }
                }
              }
            
        }
        return options;
    }

    createChartSeries(matchingPeriodResults) {
        let prices = [];
        let startPrices = [];
        let startPrice = matchingPeriodResults.length > 0 ? matchingPeriodResults[0].startPrice.toFixed(2): 0;
        for (let i = 0; i < matchingPeriodResults.length; i++) {
            let result = matchingPeriodResults[i];
            prices.push({x:convertLocalDateTimeToReadableString(result.periodStart + "T00:00:00Z", false), y:[startPrice, result.startPrice.toFixed(2)]});
            startPrices.push({x:convertLocalDateTimeToReadableString(result.periodStart + "T00:00:00Z", false), y:startPrice});
            if (result.isLast) {
                prices.push({x:convertLocalDateTimeToReadableString(result.periodEnd + "T00:00:00Z", false), y:[startPrice, result.endPrice.toFixed(2)]});
                startPrices.push({x:convertLocalDateTimeToReadableString(result.periodEnd + "T00:00:00Z", false), y:startPrice});
            }
        }
        let series = [
            {
                name: 'Wert [$]',
                type: 'rangeArea',
                data: prices,
                id: 'test'
            }, {
                name: 'Nulllinie (' + startPrice + ' [$])',
                type: 'line',
                data: startPrices
            }
        ]
        return series;
    }

    updateState(param, value) {
        let state = this.state;
        state[param] = value;
        this.setState(state);
    }

    editWatchListItem(item) {
        return(
            <div style={{width: "100%", backgroundColor: "#282c34", height: "50px", marginBottom: "10px", fontSize: "20px", padding: "10px"}}>
                {item.symbol}
                <span style={{float: "right", cursor: "pointer"}}>
                    <TiDeleteOutline size={20} onClick={() => this.removeWatchlistItem(item)}/>
                </span>
            </div>
        )
    }

}

export default withToast(Portfolios);

