import ReactApexChart from "react-apexcharts";
import { FcBullish, FcBearish } from "react-icons/fc";
import { AiOutlinePoweroff } from "react-icons/ai";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { VscTarget } from "react-icons/vsc";
import { GoArrowUp, GoArrowDown } from "react-icons/go";

export default function TrendingStockItem(props) {
    const trendingStock = props.stock;

    let chartSeries = [trendingStock.buyCount, trendingStock.holdCount, trendingStock.sellCount];  

    const trendIcon = props.type === "negative" ? <FcBearish size={20}/> : <FcBullish size={20} />
    const trendColor = props.type === "negative" ? "red" : "green";
    const trendVorzeichen = props.type === "negative" ? "" : "+";

    const chartOptions = {
        chart: {
            type: 'donut',
            width: '100px',
            height: '100px'
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            position: "right",
            offsetY: -20,
            offsetX: 10,
            labels: {
              colors: "#FFFFFF",
              useSeriesColors: false,
            },
            markers: {
                radius: 2,
                width: 5
            }
        },
        colors: ["#2bc336","#f4ee3a", "#f4423a"],
        labels: [
            "Buy: " + trendingStock.buyCount, 
            "Hold: " + trendingStock.holdCount, 
            "Sell: " + trendingStock.sellCount, 
        ]
    }

    const getTargetBage = () => {
        const backgroundColor = trendingStock.priceTarget > trendingStock.currentPrice ?
            "green" : (trendingStock.priceTarget < trendingStock.currentPrice ? "red" : "silver");
        return <div style={{
            backgroundColor: backgroundColor, 
            color: "white", 
            textAlign: "center",
            width: "100px",
            borderRadius: "10px",
            fontSize: "12px"}}><VscTarget /> {trendingStock.priceTarget}</div>
    }

    const getLowTargetBage = () => {
        const lowBackgroundColor = trendingStock.lowPriceTarget > trendingStock.currentPrice ?
            "green" : (trendingStock.lowPriceTarget < trendingStock.currentPrice ? "red" : "silver");        
        return <div style={{
            backgroundColor: lowBackgroundColor,
            color: "white", 
            textAlign: "center",
            width: "50px",
            borderTopLeftRadius: "10px",
            borderBottomLeftRadius: "10px",
            fontSize: "12px"}}>
                <GoArrowDown /> {trendingStock.lowPriceTarget}
        </div>
    }

    const getHighTargetBage = () => {
        const highBackgroundColor = trendingStock.highPriceTarget > trendingStock.currentPrice ?
            "green" : (trendingStock.highPriceTarget < trendingStock.currentPrice ? "red" : "silver");
        
        return <div style={{
            backgroundColor: highBackgroundColor,
            color: "white", 
            textAlign: "center",
            width: "50px",
            borderTopRightRadius: "10px",
            borderBottomRightRadius: "10px",
            fontSize: "12px"}}>
            <GoArrowUp /> {trendingStock.highPriceTarget}
        </div>
    }

    return (
        <div className="w3-quarter" style={{marginBottom: "5px"}}>
            <div className="w3-container w3-padding-16" style={{backgroundColor: "#1b1e22", borderRadius: "5px"}}>
                <div style={{display: "flex"}}>
                    <div style={{width: "40%"}}>
                        <h5>{trendIcon} {trendingStock.symbol}</h5>
                        <div style={{fontSize: "12px"}}>{trendingStock.companyName}</div>
                        <div style={{fontSize: "16px", padding: "0px 5px", textAlign: "center", backgroundColor: trendColor, borderRadius: "4px"}}>{trendVorzeichen}{trendingStock.priceChangePerc.toFixed(2)}%</div><br />
                        <div>{trendingStock.currentPrice}$</div>
                    </div>
                    <div style={{width: "60%"}}><ReactApexChart width={200} options={chartOptions} series={chartSeries} type="donut" /></div>
                </div>
                <hr />
                <div style={{display: "flex"}}>
                    <div style={{width: "50%"}}>
                        {getTargetBage()}
                    </div>
                    <div style={{width: "50%", display: "flex", textAlign: "end"}}>
                        <div style={{textAlign: "end"}}>
                            {getLowTargetBage()}
                        </div>
                        <div style={{width: "50%"}}>
                            {getHighTargetBage()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );


}