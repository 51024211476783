
const upperCaseChars = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
const lowerCaseChars = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'];
const allowedSpecialChars = ['_','-','+','0','1','2','3','4','5','6','7','8','9'];

export function validateFirstChar(username) {
    //Make sure the first char is not a special char (even an allowed one)
    return !(!upperCaseChars.includes(username[0]) && !lowerCaseChars.includes(username[0]));
}
export function validateUserName(username) {
    //Make sure the rest of the username is allowed
    const allAllowedChars = upperCaseChars.concat(lowerCaseChars.concat(allowedSpecialChars));
    for(let i = 1; i < username.length; i++) {
        if(!allAllowedChars.includes(username[i])) {
            return false;
        }
    }

    //No errors -> Username is allowed
    return true;
}