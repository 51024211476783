import { VscDashboard } from "react-icons/vsc";
import {Spinner, Dropdown, DropdownButton, ButtonGroup} from "react-bootstrap";
import {withToast} from "../util/ToastService";
import {GetFilteredTramisResources} from "../clients/TramisClient";
import './../../App.css';
import React from "react";
import TrendingStocksList from "./TrendingStocksList";

class TrendingStocks extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            token: props.token,
            accountId: props.accountId,
            positiveStocks: [],
            negativeStocks: [],
            sortBy: "popularity",
            sortByLabel: "Popularity",
            sortDirection: "desc",
            sortDirectionLabel: "Desc",
            showBuyItemModal: false
        }
    }

    async componentDidMount() {
        await this.loadTrendingStocks();
    }

    render() {
        return (
            <div className="w3-main" style={{marginLeft: "300px", marginTop:"43px"}}>

                {/* Header */}
                <header className="w3-container" style={{paddingTop:"50px"}}>
                    <h2><VscDashboard/>&#xA0;Trending Stocks</h2>
                </header>

                <div className="w3-container board-content-box">
                    <div style={{width: "100%", height: "50px", fontSize: "20px"}}>
                    
                        <ButtonGroup>
                            <DropdownButton as={ButtonGroup} variant="outline-secondary"
                                            title={this.state.sortByLabel}>
                                <Dropdown.Item onClick={() => {
                                    this.updateState('sortBy', "popularity");
                                    this.updateState('sortByLabel', "Popularity");
                                    this.loadTrendingStocks();
                                }}>Popularity</Dropdown.Item>
                                <Dropdown.Item onClick={() => {
                                    this.updateState('sortBy', "priceChangePerc");
                                    this.updateState('sortByLabel', "Price Change");
                                    this.loadTrendingStocks();
                                }}>Price Change</Dropdown.Item>
                            </DropdownButton>
                            <DropdownButton as={ButtonGroup} variant="secondary"
                                            title={this.state.sortDirectionLabel}>
                                <Dropdown.Item onClick={() => {
                                    this.updateState('sortDirection', "desc");
                                    this.updateState('sortDirectionLabel', "Desc");
                                    this.loadTrendingStocks();
                                }}>Desc</Dropdown.Item>
                                <Dropdown.Item onClick={() => {
                                    this.updateState('sortDirection', "asc");
                                    this.updateState('sortDirectionLabel', "Asc");
                                    this.loadTrendingStocks();
                                }}>Asc</Dropdown.Item>
                            </DropdownButton>
                        </ButtonGroup>
                    
                    </div>
                    <hr/>
                     {/* Trending Stocks List */}
                     {this.state.loading ? 
                        <div style={{textAlign: "center"}}>
                            <Spinner animation="border" />
                            <br />
                            Lade...
                        </div> 
                        :
                        <TrendingStocksList positiveStocks={this.state.positiveStocks} negativeStocks={this.state.negativeStocks} />
                    }
                     
                </div>
            </div>
        );
    }


    //-------------
    // API Methods
    //-------------
    async loadTrendingStocks() {
        this.updateState('loading', true);
        const filter = {
            per_page: 100,
            sortBy: this.state.sortBy,
            sortDirection: this.state.sortDirection
        };
        let response = await GetFilteredTramisResources('api/trending-stocks', filter);
        if (!response.error && response.result != null) {
            let positiveStocks = response.result.filter(p => p.priceChangePerc >= 0);
            let negativeStocks = response.result.filter(p => p.priceChangePerc < 0);
            this.updateState('positiveStocks', positiveStocks);
            this.updateState('negativeStocks', negativeStocks);
        }
        this.updateState('loading', false);
    }

    //--------
    // HELPERS
    //--------
    updateState(param, value) {
        let state = this.state;
        state[param] = value;
        this.setState(state);
    }
}

export default withToast(TrendingStocks);

