const GlobalConstants = {
    //General details
    ENVIRONMENT: process.env["REACT_APP_CUSTOM_NODE_ENV"],

    //Server settings
    TRAMIS_CLIENT: process.env["REACT_APP_TRAMIS_CLIENT"],
    FINNHUB_CLIENT: process.env["REACT_APP_FINNHUB_CLIENT"],
    FINNHUB_TOKEN: process.env["REACT_APP_FINNHUB_TOKEN"],
    FINNHUB_WEBHOOK_SECRET: process.env["REACT_APP_FINNHUB_WEBHOOK_SECRET"],
};

export default GlobalConstants;