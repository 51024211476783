import axios from "axios";
import GlobalConstants from "../config/GlobalConstants";
//------------
// API METHODS
//------------
export async function loginUser(email, password) {
    return axios
        .post(GlobalConstants.TRAMIS_CLIENT + "api/auth/signin", {
            email,
            password
        })
        .then(response => {
            if (response.data.token) {
                return {error: false, message: "", data: response.data}
            } else {
                return {error: true, message: "Ein unbekannter Fehler ist aufgetreten: Token nicht verfügbar. Bitte versuche es später erneut.", data: {}}
            }
        })
        .catch(error => {
            let message = "";
            if(error.message.includes("401")) {
                message = "E-Mail oder Passwort ist nicht korrekt. Bitte überprüfe deine Angaben."
            } else {
                message = "Ein unbekannter Fehler ist aufgetreten. Bitte versuche es später erneut oder kontaktiere uns."
            }
            return {error: true, message: message, data: error}
        });
}

export async function registerAccount(account) {
    return axios
        .post(GlobalConstants.TRAMIS_CLIENT + "api/auth/signup", account)
        .then(response => {
            if(response.status === 200) {
                return {error: false, message: "", data:response.data}
            }
        })
        .catch(error => {
            return {error: true, message: error.response.data.message, data: error.response.status}
        })
}

export function logout({setToken, setRoles, setPermitted, setAccount}) {
    localStorage.clear();
    sessionStorage.clear();
    setToken(null);
    setRoles(null);
    setPermitted(false);
    setAccount(null);
}