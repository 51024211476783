import {GetFilteredFinnhubResources} from "../clients/FinnhubClient";
import {CreateTramisResource, GetFilteredTramisResources, GetTramisResource} from "../clients/TramisClient";

//--------
// FINNHUB
//--------
export async function GetFilteredStockSymbolsFromFinnhub(filter) {
    return await GetFilteredFinnhubResources('api/v1/stock/symbol', filter);
}

export async function SearchForSymbolsOnFinnhub(query) {
    return await GetFilteredFinnhubResources('api/v1/search', {q: query});
}

export async function GetCompanyProfileFromFinnhub(symbol) {
    return await GetFilteredFinnhubResources('api/v1/stock/profile2', {symbol: symbol});
}

//-------
// TrAmis
//-------
export async function GetFilteredStockSymbolsFromTramis(filter) {
    return await GetFilteredTramisResources('api/symbols', filter);
}

export async function SynchronizeSymbols(exchange, symbols) {
    return await CreateTramisResource('api/symbols/synchronize', {exchange: exchange, symbols: symbols});
}

export async function GetLastSyncTimestamp(exchange) {
    return await GetFilteredTramisResources('api/symbols/last-sync', {exchange: exchange});
}

export async function GetCompanyProfileFromTramis(symbol) {
    return await GetTramisResource('api/companies/' + symbol);
}

export async function SynchronizeCompanyProfile(profile, symbol) {
    return await CreateTramisResource('api/companies/synchronize', {companyProfile: profile, symbol: symbol});
}