import './App.css';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {useToken, useRoles, usePermitted, isInRole, useAccount } from "./components/auth/TokenStorageService";
import Authentication from "./components/auth/Authentication";
import Portfolios from "./components/portfolios/Portfolios";
import {Redirect} from "react-router";
import Registration from "./components/auth/Registration";
import Symbols from "./components/symbols/Symbols";
import SymbolDetails from "./components/symbols/SymbolDetails";
import AdminDashboard from "./components/admin/AdminDashboard";
import AdminAnalysisTest from "./components/admin/AdminAnalysisTest";
import Recommendations from './components/recommendations/Recommendations';
import AppNavbar2 from './components/global/AppNavbar2';
import TrendingStocks from './components/trendingStocks/TrendingStocks';

export default function App() {

  //Authentication
  const {token, setToken} = useToken();
  const {roles, setRoles} = useRoles();
  const {permitted, setPermitted} = usePermitted();
  const {account, setAccount} = useAccount();

  const notPermitted = () => {
    return (
        <>Oops...<br />You're not permitted to view this page!</>
    )
  }

  return (
      <>
        {/* Routing */}
        <BrowserRouter>
          {/* Navbar */}
          <AppNavbar2 permitted={permitted} setToken={setToken} roles={roles} setRoles={setRoles} setPermitted={setPermitted} account={account} setAccount={setAccount}/>
          <Switch>
            {/* Authentication */}
            <Route exact={true} path={"/login"}  render={(props) => (
                <Authentication {...props} setToken={setToken} setRoles={setRoles} setPermitted={setPermitted} setAccount={setAccount}/>
            )} />
            <Route exact={true} path={"/register"}  render={(props) => (
                <Registration {...props}/>
            )} />

            {/* Recommendations */}
            <Route exact={true} path={"/recommendations"} render={(props) => (
              <>{permitted ? <Recommendations {...props}/> : <Redirect to="/login" />}</>
            )} />
            {/* Trending-Stocks */}
            <Route exact={true} path={"/trending-stocks"} render={(props) => (
              <>{permitted ? <TrendingStocks {...props}/> : <Redirect to="/login" />}</>
            )} />

            {/* Symbols */}
            <Route exact={false} path={"/company"}  render={(props) => (
                <>{permitted ? <SymbolDetails token={token} /> : <Redirect to="/login" />}</>)} />
            <Route exact={true} path={"/symbols"}  render={(props) => (
                <>{permitted ? <Symbols token={token} /> : <Redirect to="/login" />}</>)} />

            {/* ADMIN */}
            <Route exact={true} path={"/admin/test-analysis"} render={() => (
                <>{isInRole("ROLE_ADMIN") ? <AdminAnalysisTest /> : notPermitted()}</>
            )} />
            <Route exact={true} path={"/admin"} render={() => (
                <>{isInRole("ROLE_ADMIN") > -1 ? <AdminDashboard /> : notPermitted()}</>
            )} />

            {/* MAIN -> DASHBOARD */}
            <Route exact={true} path={"/dashboard"}  render={(props) => (
                <>{permitted ? <Portfolios token={token} accountId={account.id} /> : <Redirect to="/login" />}</>)} />
            <Route path={"/"}  render={(props) => (
                <>{permitted ? <Redirect to="/dashboard" /> : <Redirect to="/login" />}</>)} />
          </Switch>
        </BrowserRouter>
      </>
  );
}
