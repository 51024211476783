import { withToast } from "../util/ToastService";
import React from "react";
import { Spinner } from "react-bootstrap";
import { GetTramisResource } from "../clients/TramisClient";
class Recommendations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            recommendations: [],
            page: 1,
            symbolLike: null,
            symbolNotLike: null
        }
    }

    async componentDidMount() {
        await this.loadRecommendations(1);
    }

    render() {
        if (this.state.loading) {
            return (
                <div style={{width: "100%", textAlign: "center", marginTop: "50px"}}>
                                                <Spinner animation="border" />
                                                <br />
                                                Lade...
                                            </div>
            );
        }

        return (
        <>
            Hello World
        </>
        );
    }

    /* API METHODS */
    async loadRecommendations(page) {
        this.updateState('loading', true);
        let query = 'api/recommendations?page=' + page;
        if (this.state.symbolLike != null) {
            query += '&symbolLike=' + this.state.symbolLike;
        }
        if (this.state.symbolNotLike != null) {
            query += '&symbolNotLike=' + this.state.symbolNotLike;
        }
        let response = await GetTramisResource(query);
        if (!response.error) {
            this.setState(prevState => ({
                ...prevState,
                recommendations: response.result,
                page: page,
                loading: false
            }));
        }
    }

    /* HELPER METHODS */
    updateState(param, value) {
        let state = this.state;
        state[param] = value;
        this.setState(state);
    }
}

export default withToast(Recommendations);