import { useState } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { GetTramisResource } from '../clients/TramisClient';

export default function PurchaseItemSellModal(props) {
    const [confirm, setConfirm] = useState(false);
    const [modalText, setModalText] = useState("");

    let name = props.stock && props.stock.purchaseStatus && props.stock.purchaseStatus.symbol;
    const sellItem = async() => {
        const response = await GetTramisResource("api/accounts/" + props.account.id + "/purchases/" + props.stock.purchaseStatus.watchlistPurchaseId + "/sell");
        if (response.error) {
            setModalText("Beim Verkauf ist ein Fehler aufgetreten.");
        } else {
            setConfirm(false);
            setModalText("");
            props.onHide();
        }
    }

    return (
        <Modal
            {...props}
            size="lg"
            centered
            aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header>
                <Modal.Title>
                    {name}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {modalText}
                {confirm ?
                    <>
                    <Button style={{width: "100%", marginBottom: "10px"}} variant="danger" onClick={() => sellItem()}>Bestätigen</Button>
                    <Button style={{width: "100%"}} variant="secondary" onClick={() => setConfirm(false)}>Abbrechen</Button>
                    </>
                :
                    <>
                        <Button style={{width: "100%", marginBottom: "10px"}} variant="danger" onClick={() => setConfirm(true)}>Verkaufen</Button>
                        <Button style={{width: "100%"}} variant="secondary" onClick={props.onHide}>Schließen</Button>
                    </>
                }

            </Modal.Body>
        </Modal>
    );
}