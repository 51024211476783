import { useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { CreateTramisResource } from '../clients/TramisClient';

export default function PurchaseItemBuyModal(props) {

    const [symbol, setSymbol] = useState(null);
    const [price, setPrice] = useState(null);
    const [lowThreshold, setLowThreshold] = useState(null);
    const [highThreshold, setHighThreshold] = useState(null);

    const [modalText, setModalText] = useState("");
    let accountId = props.accountId; 
    let watchlistId = props.watchlistId;

    const buyItem = async() => {
        const watchlistItemResponse = await CreateTramisResource("api/accounts/" + accountId + "/watchlists/" + watchlistId + "/items?symbol=" + symbol, {});
        if (watchlistItemResponse.error || watchlistItemResponse.result == null || watchlistItemResponse.result.id == null) {
            setModalText("Der Stock konnte diesem Portfolio nicht hinzugefügt werden:" + watchlistItemResponse.message);
        } else {
            setModalText("");
            let request = "api/accounts/" + accountId + "/watchlists/" + watchlistId + "/items/" + watchlistItemResponse.result.id + "/buy?price=" + price;
            if (lowThreshold != null && lowThreshold !== "") {
                request = request + "&warningPrice=" + lowThreshold;
            }
            if (highThreshold != null && highThreshold !== "") {
                request = request + "&errorPrice=" + highThreshold;
            }
            const buyResponse = await CreateTramisResource(request);
            if (buyResponse.error) {
                setModalText("Der Kauf dieses Stocks konnte konnte nicht durchgeführt werden:" + buyResponse.message);
            } else {
                props.onReloadWatchlist();
                props.onHide();
            }
        }
    }

    return (
        <Modal
            {...props}
            size="lg"
            centered
            aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header>
                <Modal.Title>Stock hinzufügen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="formSymbol">
                        <Form.Label>Stock</Form.Label>
                        <Form.Control placeholder="Suche nach einem Stock ('AAPL', 'GOOGL',...)" onChange={(e) => setSymbol(e.target.value)} />
                    </Form.Group>

                    <Form.Label>Betrag</Form.Label>
                    <InputGroup className="mb-3" controlId="formPrice">
                        <InputGroup.Text>$</InputGroup.Text>
                        <Form.Control type="number" placeholder="Der Preis des gekauften Portfolios" onChange={(e) => setPrice(e.target.value)}/>
                    </InputGroup>
                    
                    <Form.Label>Thresholds (optional)</Form.Label>
                    <InputGroup className="mb-3" controlId="formThresholds">
                        <Form.Control type="number" placeholder="Weich" onChange={(e) => setLowThreshold(e.target.value)}/>
                        <InputGroup.Text>$</InputGroup.Text>
                        <Form.Control type="number" placeholder="Hart" onChange={(e) => setHighThreshold(e.target.value)}/>
                        <InputGroup.Text>$</InputGroup.Text>
                    </InputGroup>
                    <Form.Text className="text-muted">
                        Überschreitet der Gewinn oder Verlust den Threshold, wird eine Benachrichtigung verschickt.
                    </Form.Text>
                    <br /><br />

                    {modalText}

                    <Button style={{width: "100%", marginBottom: "10px"}} variant="success" onClick={() => buyItem()}>Kaufen</Button>
                    <Button style={{width: "100%"}} variant="secondary" onClick={props.onHide}>Schließen</Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}