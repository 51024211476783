import { useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import Strings from "../config/Strings";
import { FcCollapse, FcExpand } from "react-icons/fc";
import { convertLocalDateTimeToReadableString } from "../util/ConverterUtil";

export default function PurchaseDetails(props) {
    const [details] = useState(props.purchaseDetails);
    const [showInfos, setShowInfos] = useState(false);
    if (details == null) {
        return (
            <>
                <div style={{fontWeight: "bold", fontSize: "18px"}}>Trend:</div>
                <div style={{width: "100%", fontSize: "16px", marginTop: "20px", fontStyle: "italic"}}>
                    <AiOutlineInfoCircle size={18} /> {Strings.DASHBOARD_NO_PURCHASE_DETAILS}
                </div>
                <hr />
            </>
        );
    }

    const trendColor = details.priceChange < 0 ? "red" : "green";
    const trendVorzeichen = details.priceChange < 0 ? "" : "+";

    const changePercentClass = details.priceChangePercent < 0 ? "w3-container w3-center w3-padding w3-red" : "w3-container w3-center w3-padding w3-green";
    const changePercentBarWidth = Math.abs(100 * details.priceChangePercent).toFixed(0) <= 100 ? Math.abs(100 * details.priceChangePercent).toFixed(0) : 100;
    const changePercentStyle = {width: changePercentBarWidth + "%"}

    const avgChangePercentClass = details.changeAveragePercent < 0 ? "w3-container w3-center w3-padding w3-red" : "w3-container w3-center w3-padding w3-green";
    const avgChangePercentBarWidth = Math.abs(100 * details.changeAveragePercent).toFixed(0) <= 100 ? Math.abs(100 * details.changeAveragePercent).toFixed(0) : 100;
    const avgChangePercentStyle = {width: avgChangePercentBarWidth + "%"}

    const positiveChangePercentClass = details.positiveChangeAveragePercent < 0 ? "w3-container w3-center w3-padding w3-red" : "w3-container w3-center w3-padding w3-green";
    const positiveChangePercentBarWidth = Math.abs(100 * details.positiveChangeAveragePercent).toFixed(0) <= 100 ? Math.abs(100 * details.positiveChangeAveragePercent).toFixed(0) : 100;
    const positiveChangePercentStyle = {width: positiveChangePercentBarWidth + "%"}

    const negativeChangePercentClass = details.negativeChangeAveragePercent < 0 ? "w3-container w3-center w3-padding w3-red" : "w3-container w3-center w3-padding w3-green";
    const negativeChangePercentBarWidth = Math.abs(100 * details.negativeChangeAveragePercent).toFixed(0) <= 100 ? Math.abs(100 * details.negativeChangeAveragePercent).toFixed(0) : 100;
    const negativeChangePercentStyle = {width: negativeChangePercentBarWidth + "%"}
    const prognoseVorzeichen = details.priceChangePrognose >= 0 ? "+" : "";
    return (
        <>
            <div style={{display: "flex"}}>
                <div style={{flex: 1}}>
                    <div style={{fontSize: "16px"}}>Einstiegspreis:<br /><b>{details.paid.toFixed(2)} $</b></div>
                    <div style={{fontSize: "16px"}}>Aktueller Preis:<br /><b>{details.endPrice.toFixed(2)} $</b> </div>
                    <div style={{fontSize: "16px"}}>Letzte Aktualisierung:<br /><b>{convertLocalDateTimeToReadableString(details.lastUpdateAt)}</b></div>
                </div>
                <div style={{flex: 1, textAlign: "end", padding: "5px 0"}}>
                    <span style={{backgroundColor: trendColor, color: "white", fontSize: "30px", padding: "2px 10px", fontWeight: "lighter", borderRadius: "5px"}}>{trendVorzeichen}{details.priceChange.toFixed(2)} $</span><br /><br /><br />
                    <div style={{fontSize: "16px"}}>Prognose:<br /><b>{prognoseVorzeichen}{details.priceChangePrognose.toFixed(2)} $</b></div>
                </div>
            </div>
            <br />
            {showInfos ?
                <>
                    <div style={{textAlign: "center", cursor: "pointer"}} onClick={() => setShowInfos(false)}>
                    <FcCollapse /> Details schließen 
                    </div><br />
                    <div>Prozentualer Trend des Portfolios:</div>
                    <div className="w3-grey">
                        <div className={changePercentClass} style={changePercentStyle}>{100*details.priceChangePercent.toFixed(2)}%</div>
                    </div><br />
                    <div>Durchschnittlicher Trend/Stock:</div>
                    <div className="w3-grey">
                        <div className={avgChangePercentClass} style={avgChangePercentStyle}>{100*details.changeAveragePercent.toFixed(2)}%</div>
                    </div><br />
                    <div>Durchschnittlicher Trend der positiven Stocks:</div>
                    <div className="w3-grey">
                        <div className={positiveChangePercentClass} style={positiveChangePercentStyle}>{100*details.positiveChangeAveragePercent.toFixed(2)}%</div>
                    </div><br />
                    <div>Durchschnittlicher Trend der negativen Stocks:</div>
                    <div className="w3-grey">
                        <div className={negativeChangePercentClass} style={negativeChangePercentStyle}>{100*details.negativeChangeAveragePercent.toFixed(2)}%</div>
                    </div>
                </>
            :
                <>
                    <div style={{textAlign: "center", cursor: "pointer"}} onClick={() => setShowInfos(true)}>
                        <FcExpand /> Weitere Details
                    </div>
                </>
            }

            <hr />
        </>
    );
}